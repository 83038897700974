export const DEFAULT_STATE = {
  isCopyMeSelected: false,
  selectedEmails: [],
  emailMessage: ''
}

export const SHARE_MODAL_TABS = {
  mycompany: 'MyCompany',
  talentneuron: 'TalentNeuron'
}

export const OPERATOR = {
  not: 'not',
  req: 'req'
}
