import React, { useState, useEffect, useRef } from 'react'
import { Checkbox, Input } from 'talentneuron---ui-components-library'
import { Icon, Tooltip, Modal } from 'tn-components-library'
import EthnicDiversityPopup from '../EthnicityPopUp/EthnicDiversityPopup'

function CheckBoxSection (props) {
  const [isRequestModalOpen, setRequestModalOpen] = useState(false)

  const handleLimitedCoverageClick = () => {
    setRequestModalOpen(true)
  }

  const handleClosePopup = () => {
    setRequestModalOpen(false)
  }

  return (
    <div
      className={`check-box-section ${props.className.replace('&', '')} ${props.type} ${!props.sectionIsChecked && props.categoryName === 'Occupation'
          ? 'section-disabled'
          : 'section-enabled'
        }`}
    >
      <div
        className={`${props.isSubCategory ? 'sub-category' : 'main-category'
          } check-box-section-name`}
      >
        {props.categoryName}
      </div>

      {props.fieldsList.map((data, index) => (
        <React.Fragment key={index}>
          <div
            className={`check-box-section-input ${data.edit ? 'edit-mode' : 'normal-mode'
              }`}
          >
            <Input
              placeholder={''}
              onBlur={(e) => {
                props.editField(data, e.target.value)
              }}
              autoFocus={true}
            />
            <Checkbox
              changeHandler={props.checkboxHandler()}
              checked={data.isChecked || false}
              value={{
                id: data.fieldId || `id-temp-${data.fieldName}`,
                category: data.categoryName
              }}
              name={data.fieldName}
            ></Checkbox>

            <span className="data-feed-action">
              {data.field_category_id === 'Custom fields' && (
                <i
                  className="ga icon-btn icon-animation pull-right ga-trash"
                  onClick={(ev) => props.removeCustomField(ev, data.fieldName)}
                ></i>
              )}
              <i
                className="ga icon-btn icon-animation pull-right ga-pencil"
                onClick={(ev) => props.editField(data)}
              ></i>
            </span>
            {data.limitedCoverage && (
              <div className='limited-coverage-tooltip'>
                <Tooltip
                  type="info"
                  className="subscription-credits-info-tooltip" // Applied custom class
                  title="Click to know more"
                  arrow={false}
                  placement='right-end'
                >
                  <span className="limited-coverage" onClick={handleLimitedCoverageClick}>
                    <span className="limited-coverage-text">Limited Coverage</span>
                    <span className="limited-coverage-info-icon">
                      <Icon name="Info" className="info-icon" />
                    </span>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>

        </React.Fragment>
      ))}

      {props.children}

      {isRequestModalOpen && (
        <Modal
          open={isRequestModalOpen}
          size="medium"
          onClickOutModal={() => setRequestModalOpen(false)}
        >
          <EthnicDiversityPopup intl={props.intl} onClose={handleClosePopup} />
        </Modal>
      )}
    </div>
  )
}

export default CheckBoxSection
