
class Constants {
  static get productName () {
    return 'TalentNeuron Data feeds'
  }

  static get productId () {
    return 5
  }
}

export const GROUP_A_CATEGORYID = 1
export const GROUP_B_CATEGORYID = 2
export const GROUP_C_CATEGORYID = 3

export const TOKEN_REFRESH_TIME = 1750000
export const TOKEN_REFRESH_THRESHOLD_TIME = 70
export const TN_DOMAIN = 'talentneuron.com'

export const limitedCoverageFieldIds = [63, 65, 162, 163, 164]

export default Constants
