/* eslint-disable prefer-promise-reject-errors */
import React, { useState, useContext } from 'react'

import _ from 'lodash'
import { ViewAsModal } from 'tn-components-library'
import http from '../../httpService/http'
import Config from '../../profiles'
import { Context } from '../../context'

const clientMimicError = 'Error occured while mimicking client'

const getMimicClientList = (term) =>
  http.get(Config.adminServiceURL + '/v1/mimicclient?term=' + term)
    .then((response) => response.data.data)

const beginClientMimic = (clientId) => {
  const mimicUrl = Config.adminServiceURL + '/v1/mimicclient?clientId=' + clientId
  return new Promise(function (resolve, reject) {
    http.post(mimicUrl).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      reject(error)
    })
  })
}

const ViewAs = ({ setViewAsModalOpen, logout }) => {
  const contextValue = useContext(Context)
  const [suggestions, setSuggestions] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [errMsg, setErrMsg] = useState('')

  const handleClientViewClick = async (selectedSuggestion) => {
    if (selectedSuggestion) {
      try {
        const clientMimic = await beginClientMimic(selectedSuggestion.id)
        if (!clientMimic.isError) {
          logout()
          return
        }
        setErrMsg(clientMimicError)
      } catch (e) {
        setErrMsg(clientMimicError)
      }
    }
  }

  const getSuggestions = _.debounce(async (_, searchTerm) => {
    setLoading(true)
    let suggestionData = []
    try {
      suggestionData = await getMimicClientList(searchTerm)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
    setSuggestions(suggestionData)
  }, 300)

  return <ViewAsModal
    suggetions={suggestions}
    onCancel={() => setViewAsModalOpen(false)}
    getSuggestions={getSuggestions}
    isResultLoading={isLoading}
    errorMessage={errMsg}
    setErrorMessage={setErrMsg}
    handleClientViewClick={handleClientViewClick}
    hideProspectSearch
   />
}

export default ViewAs
