import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FilterGrid } from 'talentneuron---ui-components-library'
import RowButtons from '../RowButton'
import { getMonthByNumber, timeConverterFrom24HourTo12Hour } from '../../utils'
import FeedsApi from '../../httpService/feeds'
import CopyModal from '../ModalBoxes/CopyDFModal'
import RunNow from '../RunNow'
import { injectIntl } from 'react-intl'
import ShareDFModal from '../ModalBoxes/DFShareModal/index.js'

const frequencyObject = { daily: 0, monthly: 1, weekly: 2, quarterly: 3 }

class List extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showCopyModal: false,
      copyModalData: null,
      showShareModal: false,
      shareModalData: null,
      isLoading: false,
      showRunNowProgress: false,
      sortingDirection: 'desc',
      sortingColumn: 'createdon',
      myCompanyUsers: [],
      tnUsers: [],
      currentList: this.props.currentList
    }
    this.noDataRenderer = this.noDataRenderer.bind(this)
    this.dateRenderer = this.dateRenderer.bind(this)
    this.timeRenderer = this.timeRenderer.bind(this)
    this.fileTypeRenderer = this.fileTypeRenderer.bind(this)
    this.frequencyRenderer = this.frequencyRenderer.bind(this)
    this.deleteDataFeed = this.deleteDataFeed.bind(this)
    this.copyDataFeed = this.copyDataFeed.bind(this)
    this.shareDataFeed = this.shareDataFeed.bind(this)
    this.closeShareDataFeed = this.closeShareDataFeed.bind(this)
    this.closeCopyDataFeed = this.closeCopyDataFeed.bind(this)
    this.setShowRunNowProgress = this.setShowRunNowProgress.bind(this)
    // this.setPlayNowsstatus = this.setPlayNowsstatus.bind(this)
    this.statusRenderer = this.statusRenderer.bind(this)
  }

  colsDefinition () {
    let columns = []
    columns.push(
      {
        colKey: 'name',
        type: 'alpha',
        displayName: 'Name',
        className: 'name col-md-3'
      },
      {
        colKey: 'frequency',
        type: 'integer',
        displayName: 'Freq',
        className: 'frequency col-md-2',
        renderer: this.frequencyRenderer
      },
      {
        colKey: 'time',
        type: 'integer',
        displayName: 'Run time',
        className: 'runTime col-md-2',
        renderer: this.timeRenderer
      }, {
        colKey: 'lastRun',
        type: 'integer',
        displayName: 'Last Run',
        className: 'lastRun col-md-3',
        renderer: this.lastRunRenderer
      }, {
        colKey: 'volumeOfRecords',
        type: 'integer',
        displayName: 'Total Count',
        displayName: 'Volume',
        className: 'totalCount col-md-2',
        renderer: this.totalCountRenderer
      }, {
        colKey: 'filetype',
        type: 'alpha',
        displayName: 'File type',
        className: 'fileType col-md-2',
        renderer: this.fileTypeRenderer
      }, {
        colKey: 'statusDisplayName',
        type: 'alpha',
        displayName: 'Status',
        className: 'status col-md-2',
        renderer: this.statusRenderer
      })

    columns.push({
      colKey: 'createdon',
      type: 'date',

      displayName: 'Created',
      className: 'action-cell col-md-3',
      renderer: this.dateRenderer
    })
    return columns
  }

  formatData (data) {
    const dataTemp = data.slice(0)
    return dataTemp.map(el => ({
      ...el,
      frequency: el.schedule?.frequency ? frequencyObject[el.schedule?.frequency] : 0,
      time: el.schedule?.time?.[0] ? el.schedule?.time?.[0] : 0,
      name: el.name,
      filetype: el.report?.filetype,
      createdon: el.createdon
    }))
  }

  componentDidMount () {
    document.body.style.overflowY = 'visible'
    let { currentList } = this.props || this.state
    this.props.globalState.dispatch({ type: 'set_dataFeed_list', payload: currentList })
  }

  setShowRunNowProgress (showRunNowProgress) {
    this.setState({ showRunNowProgress })
  }

  deleteDataFeed (id, e) {
    FeedsApi.deleteDataFeed(id).then(
      (value) => {
        if (value.data && !value.data.isError) {
          this.props.deleteDataFeed(id)
        }
      }
    )
  }

  copyDataFeed (data) {
    this.setState({
      showCopyModal: true,
      copyModalData: data
    })
  }

  closeCopyDataFeed (data) {
    this.setState({
      showCopyModal: false,
      copyModalData: null
    })

    data && this.props.addDataFeed(data)
  }

  shareDataFeed (data) {
    this.setState({
      showShareModal: true,
      shareModalData: data
    })
  }

  closeShareDataFeed (data) {
    this.setState({
      showShareModal: false,
      shareModalData: null
    })
  }

  setUsers = (usersArray, type) => {
    if (type === 'myCompanyUsers') {
      this.setState({
        myCompanyUsers: usersArray
      })
    } else {
      this.setState({
        tnUsers: usersArray
      })
    }
  }

  frequencyRenderer (data) {
    return data.isOneTimeRun ? (data.statusId === 4 ? 'One-time feed' : '') : data.schedule && data.schedule.frequency
  }

  fileTypeRenderer (data) {
    return data.report && data.report.filetype
  }

  statusRenderer (data) {
    return data && data.statusDisplayName
  }

  dateRenderer (data) {
    const date = new Date(data.createdon)
    const dateStr = getMonthByNumber(date.getMonth()) + ' ' + date.getDate() + ', ' + date.getFullYear()
    return (
            <div>
                <span className={'date'}>{dateStr}</span>

                <RowButtons
                    deleteDataFeed={this.deleteDataFeed}
                    copyDataFeed={this.copyDataFeed}
                    shareDataFeed={this.shareDataFeed}
                    globalState={this.props.globalState}
                    updateDataFeedStatus={this.props.updateFeedStatus}
                    setShowRunNowProgress={this.setShowRunNowProgress}
                    data={data}
                />
            </div>
    )
  }

  timeRenderer (data) {
    if (data.isOneTimeRun) { return '' }
    if (data.schedule && data.schedule.frequency !== 'monthly' && data.schedule.frequency !== 'quarterly') {
      const formatTimes = data.schedule.time.map(el => {
        if (el < 10) {
          el = '0' + el.toString()
        }
        return timeConverterFrom24HourTo12Hour(el)
      })
      return <div>{formatTimes.map((el, index) => <div key={index} className={'date-renderer'} >{el}</div>)}</div>
    }
  }

  lastRunRenderer (data) {
    return data.last_succuss_jobrun && data.last_succuss_jobrun.completed_on
  }

  totalCountRenderer (data) {
    return data.last_succuss_jobrun && data.last_succuss_jobrun.total_output_record
  }

  noDataRenderer () {
    return (
            <div>{'no data'}</div>
    )
  }

  render () {
    let { currentList } = this.props
    currentList = currentList || []
    const data = this.formatData(currentList)
    let intl = this.context.intl

    return (
            <Fragment>
                { this.state.showShareModal && <ShareDFModal myCompanyUsers={this.state.myCompanyUsers} tnUsers={this.state.tnUsers} setUsers={this.setUsers} closeShareDataFeed={this.closeShareDataFeed} showShareModal={this.state.showShareModal} shareModalData={this.state.shareModalData} intl={intl}/>}
                { this.state.showCopyModal && <CopyModal globalState={this.props.globalState} closeCopyDataFeed={this.closeCopyDataFeed} copyModalData={this.state.copyModalData} intl={intl} /> }
                {
                  this.state.showRunNowProgress &&
                  <RunNow duration={2000} interval={100}
                  timeOutDuration={7000}
                  runningLabel={intl.formatMessage({ id: 'runningLabel' })}
                  completedLabel={intl.formatMessage({ id: 'completedLabel' })}
                  setShowRunNowProgress={this.setShowRunNowProgress}/>
                }
                <div className={'data-feed-table'}>
                    <FilterGrid
                        recordPerPages={10}
                        pageSize={10}
                        dataSet={data}
                        columnDefs={this.colsDefinition()}
                        enablePagination={true}
                        initialSortColumn={this.state.sortingColumn}
                        initialSortDirection={this.state.sortingDirection}
                        customNoDataComponent={this.noDataRenderer()}
                        enablePageViewDetails={true}
                        enablePageSizeDropdown={true}
                        alwaysShowPagination={true}
                        option = {{ items: [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '25', value: 25 }] }}
                    />
                </div>
            </Fragment>

    )
  }
}

export default injectIntl(List)

List.contextTypes = {
  intl: PropTypes.object.isRequired
}
