import Config from '../profiles'
import http from './http'
import { removeParamsFromQueryString } from '../utils'

const getFeedsList = (userId) => {
  return http.get(Config.appURL + `/manager/feed/list/${userId}`)
}
const getFeedData = (feedId) => {
  return http.get(Config.appURL + `/manager/feed/get/${feedId}`)
}

const getFileConfigTypes = () => {
  return http.get(Config.appURL + '/manager/feed/reporttype/get')
}

const getConfigTypeFields = (type) => {
  return http.get(Config.appURL + `/manager/feed/field/${type}`)
}
const deleteDataFeed = (id) => {
  return http.get(Config.appURL + `/manager/feed/delete/${id}`)
}
const runNow = async (data) => {
  try {
    return await http.post(Config.appURL + '/manager/scheduler/job/invoke', data)
  } catch (err) {
    console.error(err)
  }
}
const resume = async (data) => {
  try {
    return await http.post(Config.appURL + '/manager/feed/resume', data)
  } catch (err) {
    console.error(err)
  }
}
const pause = async (data) => {
  try {
    return await http.post(Config.appURL + '/manager/feed/pause', data)
  } catch (err) {
    console.error(err)
  }
}
const getSearch = (params) => {
  return http.post(Config.appURL + '/ws/body/services/v2/lookup/multiple?', params)
}

const copyDataFeed = async (feedId, newFeedName) => {
  const url = Config.appURL + '/manager/feed/copy'
  let payload = {
    feedId: feedId,
    newFeedName: newFeedName
  }
  const result = await http.post(url, payload)

  return result.data
}

const shareDataFeed = async (feedId, newFeedName, receivers) => {
  const shareUrl = Config.appURL + '/manager/feed/share'
  let payload = {
    feedId: feedId,
    newFeedName: newFeedName,
    to: receivers
  }
  const result = await http.post(shareUrl, payload)

  return result.data
}

const translationService = async (params) => {
  let url = Config.appURL + '/ws/services/v2/querytranslator/translate/?query=' + encodeURIComponent(params)
  let result = await http.get(url)
  return result
}

const autocompleteSearchItem = async (category, val, countryCode, urlSuffix) => {
  let categoryType = category === 'certification' ? 'credential' : category /* temporary changes till we replace certifications with credetials every where */
  let suggestUrl = Config.appURL + `/ws/services/v2/autocomplete/suggest?type=${categoryType}&rows=50&query=${encodeURIComponent(val)}&country=${countryCode}` + urlSuffix
  if (category === 'source') {
    suggestUrl = removeParamsFromQueryString(suggestUrl, 'country')
  }
  let result = await http.get(suggestUrl)
  return result
}

const locationSearch = async (uriParams, query) => {
  let param = `/ws/services/v2/autocomplete/suggest?type=location&${uriParams}&query=${encodeURIComponent(query)}&rows=100`
  let result = await http.get(Config.appURL + param)
  return result
}

const getCurrencyInfo = () => {
  let param = '/ws/rates/current'
  return http.get(Config.appURL + param)
}

const lookupIndustries = (id) => {
  let param = `/ws/services/v2/lookup/reverse/naics_2017_by_id/code/id/${id}`
  return http.get(Config.appURL + param)
}

const getRecentSearches = async () => {
  const url = `${Config.adminServiceURL}/tnrecentsearch/`
  try {
    return await new Promise((resolve, reject) => {
      http.get(url).then((res) => {
        if (res?.data?.isError) {
          resolve([])
          return
        }
        resolve(res.data?.data?.tnrecentsearch || [])
      })
    })
  } catch (e) {
    return []
  }
}

const getInsights = () => {
  const url = Config.adminServiceURL + '/v1/cms/gettninsights'
  return new Promise((resolve) => {
    http
      .post(url, {
        categories: 'All',
        pageno: 1,
        totalrecords: 100,
        fromdate: '2020-01-01',
        todate: '2099-12-31',
        searchtext: ''
      })
      .then((response) => {
        resolve(response.data?.data?.insightslist || [])
      })
      .catch((e) => {
        resolve([])
      })
  })
}

const recentSearchTracking = (widgetDetails) => {
  return new Promise((resolve, reject) => {
    http.post(`${Config.adminServiceURL}/tnrecentsearch/`, widgetDetails)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
}

const getSupplySalarySupportedCountries = async () => {
  try {
    const [supplyRes, salaryRes] = await Promise.all([http.get(`${Config.dataFeedUrl}/ws/services/v2/country/detail/supply`), http.get(`${Config.dataFeedUrl}/ws/services/v2/country/detail/salary`)])
    return [supplyRes.data.data.supportedCountries, salaryRes.data.data.supportedCountries]
  } catch (error) {
    console.error(error)
  }
}

export default {
  getSearch,
  getFeedsList,
  getFeedData,
  getFileConfigTypes,
  getConfigTypeFields,
  deleteDataFeed,
  runNow,
  copyDataFeed,
  shareDataFeed,
  translationService,
  autocompleteSearchItem,
  locationSearch,
  getCurrencyInfo,
  lookupIndustries,
  resume,
  pause,
  getRecentSearches,
  getInsights,
  recentSearchTracking,
  getSupplySalarySupportedCountries
}
