import React, { useState, useEffect, useRef } from 'react'
import { removetimestamp } from '../../utils'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import CalendarHeader from './calendarheader'
import 'react-day-picker/lib/style.css'
import {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

function DateSelector (props) {
  let dayPicker = useRef < HTMLInputElement > (null)
  const [selectedDate, setSelectedDate] = useState(props.selectedDate)
  const [isInvalidDate, setIsInvalidDate] = useState(false)
  let isInvalidDateselected = false
  let showDayPicker = false
  let selectedDay

  useEffect(() => {
    if (isInvalidDate === true) {
      selectedDay = selectedDate
      isInvalidDateselected = false
      setSelectedDate(selectedDate)
      setIsInvalidDate(false)
      dayPicker.state.typedValue = ''
    }
  }, [isInvalidDate, selectedDate])

  const handleYearMonthChange = (date) => {
    dayPicker.overlayHasFocus = true
    showDayPicker = true
    setSelectedDate(date)
  }

  const onDayPickerHide = () => {
    if (isInvalidDateselected) {
      setIsInvalidDate(true)
    }
    else {
      if (selectedDay !== undefined) {
        props.changeHandler(selectedDay)
      }
      setIsInvalidDate(false)
      setSelectedDate(selectedDay !== undefined ? selectedDay : props.selectedDate)
    }
  }

  const dateChangeHandler = (pickedDay) => {
    if (selectedDay === undefined) {
      isInvalidDateselected = true
    } else {
      selectedDay = pickedDay
      isInvalidDateselected = false
      setSelectedDate(pickedDay)
    }
    props.changeHandler(pickedDay)
  }

  const todayDate = new Date()
  const startDate = new Date(todayDate.setFullYear(todayDate.getFullYear() - 4))
  const dayPickerProps = {
    firstDayOfWeek: 1,
    canChangeMonth: false,
    month: selectedDate,
    disabledDays: props.disabledDate,
    selectedDays: props.selectedDate,
    captionElement: () => (<CalendarHeader
        selectedDate={selectedDate}
        onChange={handleYearMonthChange}
        startDate={startDate}
        endDate={new Date()}
      />)
  }

  let isError = true
  if (removetimestamp(props.selectedDate) >= removetimestamp(props.disabledDate[0].before) && removetimestamp(props.selectedDate) <= removetimestamp(props.disabledDate[1].after)) {
    isError = false
  }
  const inputProps = {
    className: isError || isInvalidDate ? 'required-input-field' : '',
    readOnly: true
  }

  return (
    <div className={`calendarcontainer ${props.className}`}>
      <i className="ga ga-calendar"></i>
      <DayPickerInput
        ref={el => (dayPicker = el)}
        formatDate={formatDate}
        dayPickerProps={dayPickerProps}
        onDayPickerHide={onDayPickerHide}
        inputProps={inputProps}
        keepFocus={true}
        parseDate={parseDate}
        format="MMM DD, YYYY"
        placeholder="MMM DD, YYYY"
        value={props.selectedDate}
        onDayChange={dateChangeHandler}
      />
      {isInvalidDate === true &&
        <div className="incorrect-format">Incorrect format</div>
      }

    </div>
  )
}
export default DateSelector
