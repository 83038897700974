import React, { useState, useEffect } from 'react'
import { Button } from 'talentneuron---ui-components-library'

function useForceUpdate () {
  const [value, setValue] = useState(0)
  return () => setValue(value => value + 1)
}

function SerialBoxs (props) {
  const forceUpdate = useForceUpdate()

  const onClickBox = (value) => {
    props.boxs[value - 1].active = !props.boxs[value - 1].active
    props.onChangeBox(props.boxs)
    forceUpdate()
  }

  return (

        <div className={'multi-boxs-button'}>
            {props.boxs.map((box, i) => {
              return <Button key={i} onClick={(ev) => onClickBox(box.value)} value={box.label} outline={true} type={'secondary'} className={box.active ? 'active' : 'inactive'}/>
            }) }
        </div>
  )
}

export default SerialBoxs
