import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Pill, Input } from 'talentneuron---ui-components-library'
import { addEllipsis } from '../../utils'

function DraggableBox (props) {
  const [itemsList, setItemsList] = useState([])
  const [grid, setGrid] = useState(0)

  const characterLimitList = ['Advertiser Name', 'Employer SuperAlias', 'Original title', 'Translated title', 'Original description', 'Translated description', 'Hard skills', 'Soft skills', 'Certifications', 'Job posting URL', 'Employer']

  useEffect(() => {
    let items = props.itemsList.filter(item => item.categoryName != 'Industry') /* excluding industry details */
    setItemsList(items)
    setGrid(items.length)
  }, [props.itemsList])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    borderRadius: '14.5px',
    marginBottom: '5px',
    cursor: 'move',
    ...draggableStyle
  })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items = reorder(
      itemsList,
      result.source.index,
      result.destination.index
    )
    let selectedList = items
    props.onReorder(selectedList)
    setItemsList(items)
  }

  return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {itemsList.map((item, index) => (<Draggable key={item.id} draggableId={item.id} index={index} className={'drageable-pillbox'}>
                                {(provided, snapshot) => (
                                    <div
                                        className={'draggable-pill-box'}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}>
                                        {(<div className={`${characterLimitList.find((el) => el === item.content) ? ' container-pill-limit-input' : item.content.savedLimit ? ' container-pill-limit-input-saved' : ''}`}>
                                                <Pill className={props.pillClass} type="standard">
                                                    <span className={'container-pill-limit-label'}>{addEllipsis(item.content, (props.isCharacterLimitMenuOpen || item.content.savedLimit) ? 16 : 30)}</span>
                                                    <span className={props.iconClass}/>
                                                    {item.savedLimit && <span className={'saved-limit'}>{addEllipsis(item.charLimit, 5) + ' c.'}</span>}
                                                    {characterLimitList.find((el) => el === item.content) && <Input className={'character-limit-input'} value={item.charLimit ? item.charLimit : ''}
                                                           placeholder={'0000'}
                                                           onChange={(e) => { !isNaN(e.target.value) && props.setCharacterLimitValue(e.target.value, item) }}/>}
                                                    <span className={'ga pillBoxLogicIcon ga-close01 delete'} onClick={() => props.onDelete(item.content, { category: item.categoryName, id: item.fieldId })}/>
                                                </Pill>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
  )
}

export default DraggableBox
