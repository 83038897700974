import React, { Fragment, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Modal, Button } from 'talentneuron---ui-components-library'
import { addEllipsis } from '../../utils'
import FeedsApi from '../../httpService/feeds'
import NameDuplicationModal from '../Step/NameDuplicationModal'
import { useHistory } from 'react-router-dom'
import { doSave } from '../Step/helpers/buildSearchHelper'

export default function CopyModal ({ intl, globalState, copyModalData, closeCopyDataFeed }) {
  const history = useHistory()
  let label = copyModalData.name
  let [dfName, setDfName] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showReplaceMessage, setShowReplaceMessage] = useState(false)

  const copyDataFormat = (data) => {
    return {
      id: data.id,
      name: data.name,
      statusName: 'pending',
      report: data.config.report,
      schedule: data.config.schedule,
      createdon: data.createdon,
      isactive: data.isactive
    }
  }

  const onReplaceClick = () => {
    FeedsApi.getFeedData(copyModalData.id).then(response => {
      if (!response.data.errorData) {
        let dataToCopy = Object.assign({}, response.data.data[0])
        const feedIdToOverride = globalState.dataFeedList.filter(feed => feed.name === dfName)[0].id
        dataToCopy.name = dfName
        dataToCopy.id = feedIdToOverride
        doSave(dataToCopy, true) // true for editMode
          .then(resp => {
            if (!resp.errorData && !resp.isError) {
              let copyObj = copyDataFormat(dataToCopy)
              closeCopyDataFeed(copyObj)
            }
          })

        setShowReplaceMessage(false)

        closeCopyDataFeed()
      }
    }).catch(err => {
      console.log('error while getting config types', err)
    })
  }
  const onCreateCopy = async (feedId, feedName, isReplace = false) => {
    let resp = await FeedsApi.copyDataFeed(feedId, feedName)

    if (resp.data) {
      let copyObj = copyDataFormat(resp.data)
      closeCopyDataFeed(copyObj)
    } else if (resp.errorData) {
      const message = resp.errorData.message
      if (message.split('=')[0].indexOf('name, gemini_user_id') >= 0 && message.split('=')[1].indexOf('already exists') >= 0) {
        setShowReplaceMessage(true)
      }
    } else {
      setShowErrorMessage(true)
    }
  }

  let ErrorMessage = () => {
    return (
            <React.Fragment>
                <div className="prompt-title">
                    <span>Something went wrong while copying the datafeed</span>
                    <span className="name"><b>{label} </b></span>

                </div>
                <div className="prompt-body" >{intl.formatMessage({ id: 'info_project_overwrite' })}</div>

                <div className="pull-right btn-position">
                    <Button
                        className="margin-right-10"
                        type={'primary'} outline={false}
                        value={intl.formatMessage({ id: 'back' })}
                        onClick={() => {
                          setShowReplaceMessage(false)
                          setShowErrorMessage(false)
                        }

                        }
                    />
                    <Button
                        className="width-auto"
                        type="ghost"
                        value={intl.formatMessage({ id: 'cancel' })}
                        onClick={() => closeCopyDataFeed()}

                    />
                </div>
            </React.Fragment>
    )
  }

  return (

        <Fragment>
            { !showReplaceMessage
              ? <Modal isModalOpen={true} override={false} backDrop={true} containerClassName="copy-modal" isCloseOnOustsideClick={false} closeModal={() => closeCopyDataFeed()}>
                { !showReplaceMessage && !showErrorMessage &&
                <Fragment>
                    <div className="prompt-title">{intl.formatMessage({ id: 'copy_profile' })}
                        <span className="darktooltip" data-for={'copyHeader'} data-tip={label} data-tip-disable={label.length <= 30}>
                            <b>{addEllipsis(label, 30)}</b>
                        </span>
                        <ReactTooltip place="bottom" id={'copyHeader'} className='tooltip' effect="solid" />
                    </div>
                    <div className="margin-bottom-15">
                        <input type="text" onChange={(e) => {
                          setDfName(e.target.value)
                        }}
                               value={dfName}
                               placeholder={intl.formatMessage({ id: 'name_of_df' })}
                        />
                    </div>
                    <div className="button-row">
                        <Button type={dfName.length ? 'primary' : 'disabled'} className="pull-right" value={intl.formatMessage({ id: 'save_as_copy' })} onClick={() => { dfName.length && onCreateCopy(copyModalData.id, dfName, false) }} />
                        <Button type={'secondary'} outline={true} className="pull-right" value={intl.formatMessage({ id: 'cancel' })} onClick={() => closeCopyDataFeed()} />
                    </div>
                </Fragment>
                }
                {showErrorMessage && <ErrorMessage></ErrorMessage>}
            </Modal>
              : <NameDuplicationModal
                handleShowModal={() => { setShowErrorMessage(false); setShowReplaceMessage(false) }}
                onReplaceClick={onReplaceClick}
                name={dfName}
                header={intl.formatMessage({ id: 'name_already_exists' })}
                subHeader={intl.formatMessage({ id: 'info_project_overwrite' })}
            />
            }
        </Fragment>

  )
}
