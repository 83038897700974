import React, { Component } from 'react'
import http from './httpService/http'
import Config from './profiles'
import { getCountriesForDropDown } from './utils'
import CsrfUtil from './countryOriginUtil'
import KeyCloakAuthInstance from './keyAuth'
import { LoadingCharts } from 'tn-components-library'
// this is the state manager , we do not need the redux
export const Context = React.createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'case_1':
      return {
        ...state,
        state_attr: action.payload
      }
    case 'set_country':
      return {
        ...state,
        userdetails: {
          ...state.userdetails,
          selectedCountry: action.payload
        }
      }
    case 'set_feed_data':
      return {
        ...state,
        currentFeedData: action.payload
      }
    case 'set_dataFeed_list':
      return {
        ...state,
        dataFeedList: action.payload
      }
    default:
      return state
  }
}
export class Provider extends Component {
// the state is supposed to be populated from a server call
  constructor (props) {
    super(props)
    this.state = {
      dispatch: action => this.setState(state => reducer(state, action)),
      contextReady: false,
      userdetails: {},
      currentFeedData: {},
      isEditMode: false,
      dataFeedList: [],
      isLoading: true,
      buildValidationErrorMsg: '',
      setBuildValidationErrorMsg: this.setBuildValidationErrorMsg.bind(this)
    }
    this.setCurrentFeedData = this.setCurrentFeedData.bind(this)
    this.setIsEditMode = this.setIsEditMode.bind(this)
    this.modifyDfPermissionsCountries = this.modifyDfPermissionsCountries.bind(this)
  }

  setIsEditMode (val) {
    this.setState(prev => ({
      ...prev,
      isEditMode: val
    }))
  }

  componentDidMount () {
    this.fetchInitilizationData()
  }

  setCurrentFeedData (data) {
    this.setState(prev => ({
      ...prev,
      currentFeedData: data
    }))
  }

  setBuildValidationErrorMsg (msg) {
    this.setState(prev => ({ ...prev, buildValidationErrorMsg: msg }))
  }

  getModifiedCountryIdsOfSubLocations (locations, modifiedCountries) {
    let modifiedLocations = JSON.parse(JSON.stringify(locations))
    for (const [key, list] of Object.entries(modifiedLocations)) {
      if (key != 'countries') {
        list.forEach(item => {
          let country = modifiedCountries.find(elem => elem.code == item.country.code)
          if (country && item.country) {
            item.country.id = country.id
          }
        })
      } else {
        list.forEach(item => {
          let country = modifiedCountries.find(elem => elem.code == item.code)
          if (country) {
            item.id = country.id
          }
        })
      }
    }
    return modifiedLocations
  }

  // this is needed as country id what we get from admin maps to country table in gemini, Whereas while saving DF we have to save country id mapping to WA
  modifyDfPermissionsCountries (data) {
    let modifiedData = JSON.parse(JSON.stringify(data))
    let countriesList = getCountriesForDropDown(data.dfpermissions)
    let newCountriesList = countriesList.length ? JSON.parse(JSON.stringify(countriesList)) : []
    const apiUrl = `${Config.appURL}/ws/services/v2/lookup/reverse/country/code/id/`
    const myArrayOfData = countriesList.map(function (country) {
      return { webAddress: apiUrl + country.code }
    })

    let promises = []

    myArrayOfData.forEach(function (countryElement) {
      const myUrl = countryElement.webAddress
      promises.push(http.get(myUrl))
    })

    Promise.all(promises).then((results) => {
      results.forEach((response) => {
        let matchedCountry = newCountriesList.find(elem => elem.code == response.data.data.id)
        if (matchedCountry) {
          matchedCountry.id = matchedCountry && parseInt(response.data.data.value)
        }
      })

      modifiedData.dfpermissions.locations = this.getModifiedCountryIdsOfSubLocations(modifiedData.dfpermissions.locations, newCountriesList)
      this.setState((prevState) => {
        return {
          userdetails: {
            ...modifiedData
          },
          contextReady: true,
          isLoading: false
        }
      }
      )
    })
  }

  fetchInitilizationData () {
    const userDetailsUrl = (Config.loginURL.indexOf('/login') > -1 ? Config.loginURL.replace('/login', '') : Config.loginURL) +
        '/userdetails/datafeeds'
    http.get(userDetailsUrl)
      .then((res) => {
        if (res.data && res.data.Success && !res.data.IsError) {
          let data = res.data.Data[0]
          if (data != null) {
            if (!data.isactive) {
              KeyCloakAuthInstance.logout()
              return
            }
            if (!data.datafeedsenabled) {
              window.location = Config.tnHomePageUrl
              return
            }
            window.df.UserContext = JSON.parse(JSON.stringify(data))
          }
          this.modifyDfPermissionsCountries(data)
        } else {
          console.log('failed to fetch context initilization data', res.data)
          window.location.replace(Config.loginURL)
        }
      })
      .catch((err) => {
        console.log('failed to fetch context initilization data', err)
      })
  }

  render () {
    return (
            <div>
              {this.state.isLoading && <div className='root-loading'><LoadingCharts title='Loading' subTitle='TalentNeuron' /></div>}
               {<Context.Provider value={{ ...this.state, setIsEditMode: this.setIsEditMode }} >
                    {this.state.contextReady ? this.props.children : null }
                </Context.Provider>}
            </div>
    )
  }
}

export const Consumer = Context.Consumer
