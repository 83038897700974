import http from '../../httpService/http'
import Config from '../../profiles/index'
export const getUsersOfSharedSearch = async (term = '', restrictToUserClient = '') => {
  const trimmedTerm = term.trim()
  const response = await http.get(
    Config.adminServiceURL +
        `/v1/searchquery/getusersforsharedsearch?term=${trimmedTerm}&noofresults=200${restrictToUserClient}`
  )
  return response
}

export const getUsersOfAccountManagement = async () => {
  const response = await http.get(
    Config.adminServiceURL + '/v1/searchquery/getaccountmanagementusers?'
  )
  return response
}

export const getSearchParamsForSharedSearch = async (feedId) => {
  return await http.get(Config.appURL + `/manager/feed/get/${feedId}`)
}

export const getSharedSearchLookupData = async (params) => {
  return await http.post(Config.appURL + '/ws/body/services/v2/lookup/multiple?', params)
}

export const shareDataFeed = async (feedId, newFeedName, receivers) => {
  const shareUrl = Config.appURL + '/manager/feed/share'
  let payload = {
    feedId: feedId,
    newFeedName: newFeedName,
    to: receivers
  }
  return await http.post(shareUrl, payload)
}

export const sendEmailForSharedDF = async (emailNotificationPayload) => {
  const notifyUrl = Config.adminServiceURL + '/v1/share/notify'
  return await http.post(notifyUrl, emailNotificationPayload)
}
