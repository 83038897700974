export default [
  {
    id: 0,
    category: 'location',
    title: 'Location',
    infoMessage: 'location_tip',
    enableSearch: false,
    URL: '/ws/services/v2/locations/top/msa?country=US&rows=100',
    propName: 'elements',
    logicConfig: {
      or: false,
      req: true,
      opt: false,
      not: true,
      defaultLogic: 'req'
    }
  },
  {
    title: 'Keywords',
    id: 10,
    category: 'query',
    URL: '/ws/services/v2/querytranslator/translate/?query=',
    infoMessage: 'keyword_tip'
  },
  {
    id: 1,
    category: 'function',
    title: 'Function',
    infoMessage: 'function_tip',
    enableSearch: false,
    URL: '/ws/services/v2/functions/search?showstaffing=true&country=US',
    propName: 'elements',
    logicConfig: {
      or: true,
      req: false,
      opt: false,
      not: true,
      defaultLogic: 'req'
    }
  },
  {
    id: 2,
    category: 'occupation',
    infoMessage: 'occupation_tip',
    title: 'Occupation',
    subtitle: 'Top occupations',
    enableSearch: true,
    placeHolder: 'Search for occupation',
    URL: '/ws/services/v2/occupations/search?occupationlevel=occ8&showstaffing=true',
    searchURL: '/ws/services/v2/autocomplete/suggest?type=occupation&',
    propName: 'occupations',
    logicConfig: {
      or: true,
      req: false,
      opt: false,
      not: true,
      defaultLogic: 'req'
    },
    enableSOC: false,
    SOCOccupationTree: {
      URL: '/ws/services/v2/occupations/tree/soc?',
      widgetCodeName: 'SOCOccupationTree',
      category: 'occupation',
      searchParams: ''
    }
  },
  {
    id: 3,
    category: 'skill',
    infoMessage: 'skill_tip',
    title: 'Skills',
    subtitle: 'Top skills',
    enableSearch: true,
    placeHolder: 'Search for skill',
    searchURL: '/ws/services/v2/autocomplete/suggest?type=skill&',
    URL: '/ws/services/v2/skills/all/search?showstaffing=true&rows=50',
    propName: 'elements',
    logicConfig: {
      req: true,
      opt: false,
      not: true,
      or: false,
      defaultLogic: 'req'
    }
  },
  {
    id: 4,
    category: 'certification',
    infoMessage: 'certification_tip',
    title: 'Certifications',
    subtitle: 'Top certifications',
    enableSearch: true,
    placeHolder: 'Search for certification',
    searchURL: '/ws/services/v2/autocomplete/suggest?type=credential&',
    URL: '/ws/services/v2/credentials/search?&rows=50',
    logicConfig: {
      req: true,
      opt: false,
      not: true,
      or: false,
      defaultLogic: 'req'
    }
  },
  {
    title: 'Experience level',
    id: 5,
    category: 'seniority',
    infoMessage: 'experience_level_tip',
    URL: '/ws/services/v2/seniority/search?',
    propName: 'elements',
    logicConfig: {
      or: true,
      req: false,
      opt: false,
      not: true,
      defaultLogic: 'req'
    }
  },
  {
    title: 'Education level',
    id: 6,
    category: 'educationlevel',
    infoMessage: 'education_level_tip',
    URL: '/ws/services/v2/educationlevels/search?rows=6',
    propName: 'elements',
    logicConfig: {
      req: false,
      opt: false,
      or: true,
      not: true,
      defaultLogic: 'req'
    }
  },

  {
    title: 'Employment type',
    id: 9,
    category: 'employmenttype',
    URL: '/ws/services/v2/jobtypes/search?',
    propName: 'jobType',
    infoMessage: 'employment_type_tip',
    enableSearch: false,
    logicConfig: {
      req: false,
      opt: false,
      or: true,
      not: true,
      defaultLogic: 'req'
    }
  },
  {
    title: 'Custom list',
    category: 'customList',
    propName: 'customList',
    infoMessage: 'customList',
    enableSearch: false,
    logicConfig: {
      req: false,
      opt: false,
      or: true,
      not: true,
      defaultLogic: 'req'
    }
  },

  {
    title: 'Employer',
    id: 10,
    category: 'employer',
    subtitle: 'Employers',
    URL: '/ws/services/v2/employers/search?rows=100&',
    searchURL: '/ws/services/v2/autocomplete/suggest?type=employer&rows=100&',
    propName: 'data',
    infoMessage: 'employer_group_tip',
    enableSearch: true,
    logicConfig: {
      or: false,
      req: true,
      opt: false,
      not: true,
      defaultLogic: 'req'
    }
  },

  {
    title: 'Source ',
    subtitle: 'Recommended sources',
    enableSearch: true,
    placeHolder: 'Search for source',
    id: 10,
    category: 'source',
    showAllSources: false,
    URL: '/ws/services/v2/source/search?rows=100',
    searchURL: '/ws/services/v2/autocomplete/suggest?type=source&rows=100',
    propName: 'data',
    infoMessage: 'employment_type_tip',
    logicConfig: {
      or: false,
      req: true,
      opt: false,
      not: true,
      defaultLogic: 'req'
    }
  }

]
