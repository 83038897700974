import React, { useState, useEffect } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from './context'
import { createMemoryHistory } from 'history'
import Header from './components/Header/index'
import SideNav from './components/SideNav/index'
import ViewAs from './components/ViewAsModal'
import './scss/MasterStyle.scss'
import Wizard from './components/Wizard/index'
import Config from './profiles'
import Footer from './components/Footer'
import LandingPage from './container/LandingPage'
import KeyCloakAuthInstance from './keyAuth'

import en from 'react-intl/locale-data/en'
import messages_en from './localization/en.json'
import { IntlProvider, addLocaleData } from 'react-intl'
import { TNThemeProvider, LoadingCharts } from 'tn-components-library'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import httpService from './httpService/feeds'
const queryClient = new QueryClient()
const messagesList = {
  en: messages_en
}
addLocaleData([...en])
const messages = messagesList.en

const logout = () => {
  KeyCloakAuthInstance.logout()
}

function App () {
  const history = createMemoryHistory()
  const [isViewAsModalOpen, setViewAsModalOpen] = useState(false)
  const [isAuthSuccess, setIsAuthSuccess] = useState(false)
  const [insights, setInsights] = useState([])
  const [recentSearchData, setRecentSearchData] = useState([])
  useEffect(() => {
    KeyCloakAuthInstance.tryAuthentication().then(async isSuccess => {
      setIsAuthSuccess(isSuccess)
      const insightsData = await httpService.getInsights()
      const recentSearches = await httpService.getRecentSearches()
      setInsights(insightsData)
      const topRecentSearchData = recentSearches || []
      setRecentSearchData(topRecentSearchData)
    })
  }, [])
  if (isAuthSuccess) {
    return (
      // the provider is the default application state manager
      <IntlProvider locale={'en'} messages={messages}>
        <QueryClientProvider client={queryClient}>
          <Provider>
            <TNThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
              <Router history={history}>
                <React.Fragment>
                  <Header insights={insights} recentSearchData={recentSearchData}/>
                  <SideNav setViewAsModalOpen={setViewAsModalOpen} logout={logout} insights={insights} />
                  {isViewAsModalOpen && <ViewAs setViewAsModalOpen={setViewAsModalOpen} logout={logout} />}
                  <div className='contentcontainer'>
                    <Switch>
                      <Route exact path='/build-search' path={['/build-search', '/select-file-type', '/configure-file', '/schedule']} component={Wizard} />
                      <Route
                        path='/logout'
                        component={() => {
                          logout()
                          return null
                        }}
                      />
                      <Route exact path='/'>
                        <LandingPage />
                      </Route>
                    </Switch>
                  </div>
                  {/* <Footer className="footer" /> */}
                </React.Fragment>
              </Router>
            </TNThemeProvider>
          </Provider>
        </QueryClientProvider>
      </IntlProvider>
    )
  } else {
    return <div className='root-loading'><LoadingCharts title='Loading' subTitle='TalentNeuron' /></div>
  }
}

export default App
