import React, { useState, useEffect, useContext, useRef } from 'react'
import { A, Checkbox, DropDown, Input, PopoverTooltip, MultipleEmailInputbox } from 'talentneuron---ui-components-library'
import http from '../../httpService/http'
import Config from '../../profiles'
import DatePicker from 'react-datepicker'
import SerialBoxs from '../SerialBox'
import 'react-datepicker/dist/react-datepicker.css'
import { isEmpty, numberWithCommas } from '../../utils'
import gtm from '../../usagetracker/GTM'
import { injectIntl } from 'react-intl'
import isEmail from 'validator/lib/isEmail'
import { TN_DOMAIN } from '../../constants'

function Schedule (props) {
  const { formatMessage } = props.intl
  const forceUpdate = useForceUpdate()
  const defaultDaily = {
    day: [{ acronym: 'sun', label: 'S', value: 1, active: false },
      { acronym: 'mon', label: 'M', value: 2, active: true },
      { acronym: 'tue', label: 'T', value: 3, active: true },
      { acronym: 'wed', label: 'W', value: 4, active: true },
      { acronym: 'thu', label: 'T', value: 5, active: true },
      { acronym: 'fri', label: 'F', value: 6, active: true },
      { acronym: 'sat', label: 'S', value: 7, active: false }],
    hour: [{ hour: 12, amPm: 'AM', index: 0 }]
  }

  const defaultWeekly = {
    day: [{ acronym: 'sun', label: 'S', value: 1, active: false },
      { acronym: 'mon', label: 'M', value: 2, active: true },
      { acronym: 'tue', label: 'T', value: 3, active: false },
      { acronym: 'wed', label: 'W', value: 4, active: false },
      { acronym: 'thu', label: 'T', value: 5, active: false },
      { acronym: 'fri', label: 'F', value: 6, active: false },
      { acronym: 'sat', label: 'S', value: 7, active: false }],
    hour: [{ hour: 12, amPm: 'AM', index: 0 }]
  }

  let day; let excludePreviousJob; let frequency; let startingDate; let feedTime; let sendAsZip; let splitAfter; let deliverEmail; let deliverFolder; let notifyByEmail = false
  let deliverS3bucket; let deliverMode

  if (!isEmpty(props.currentFeedData) && props.isEditMode) {
    const config = props.currentFeedData.config
    if (!isEmpty(config)) {
      if (config.schedule) {
        const schedule = config.schedule
        const dayLabel = [
          { acronym: 'sun', label: 'S', value: 1, active: false },
          { acronym: 'mon', label: 'M', value: 2, active: false },
          { acronym: 'tue', label: 'T', value: 3, active: false },
          { acronym: 'wed', label: 'W', value: 4, active: false },
          { acronym: 'thu', label: 'T', value: 5, active: false },
          { acronym: 'fri', label: 'F', value: 6, active: false },
          { acronym: 'sat', label: 'S', value: 7, active: false }]
				  	day = schedule.day ? dayLabel.map(el => ({ ...el, active: schedule.day.indexOf(el.acronym) >= 0 })) : day
        feedTime = schedule.time
          ? schedule.time.map(el => (
            { hour: el === 0 ? 12 : el > 12 ? el - 12 : el, amPm: el < 12 ? 'AM' : 'PM', index: 0 }
          ))
          : feedTime
				  excludePreviousJob = schedule.excludePreviousJob
				  frequency = schedule.frequency
				  startingDate = new Date()
      }
      if (config.deliver) {
        const deliver = config.deliver
        sendAsZip = deliver.sendAsZip
        splitAfter = deliver.splitAfter !== 0 ? { isSplit: true, value: deliver.splitAfter } : { isSplit: false, value: 0 }
        const deliveryBy = deliver.deliveryBy
        if (deliveryBy.email) {
          deliverEmail = deliveryBy.email.address
          deliverMode = 'email'
        } else if (deliveryBy.sftp) {
          deliverMode = 'SFTPS'
          deliverFolder = deliveryBy.sftp.folder
          notifyByEmail = deliveryBy.sftp.notifyByEmail
        } else {
          deliverMode = 'BucketAzure'
          deliverS3bucket = deliveryBy.s3.s3bucket
          notifyByEmail = deliveryBy.s3.notifyByEmail
        }
      }
    }
  }
  let reportfrequency = props.userdetails.dfpermissions.reportfrequency.sort((a, b) => a.id - b.id)

  const [generatePeriod, setGeneratePeriod] = useState(frequency || reportfrequency[0].name.toLowerCase())
  const [everyDay, setEveryDay] = useState(day || defaultDaily.day)
  const [isDaySelected, setIsDaySelected] = useState(false)
  const [time, setTime] = useState(feedTime || defaultDaily.hour)
  const [selectedDate, setSelectedDate] = useState(startingDate || new Date())
  const [postingOnly, setPostingOnly] = useState(excludePreviousJob || false)

  const [deliverWay, setDeliverWay] = useState(deliverMode || 'email')
  const [eMail, setEmail] = useState(deliverEmail || '')
  const [folder, setFolder] = useState(deliverFolder || '')
  const [s3bucket, setS3bucket] = useState(deliverS3bucket || '')
  const [sendZip, setSendZip] = useState(sendAsZip || false)
  const [splitFile, setSplitFile] = useState(splitAfter || false)
  const [mandatory, setMandatory] = useState(false)
  const [allowedDomains, setAllowedDomains] = useState([])
  const [inputInteracted, setInputInteracted] = useState(false)

  const track = (action, label = '') => {
    gtm.trackWidgetEvent(gtm.events.DataFeedScheduler,
      {
        category: 'DataFeed Scheduler',
        action: action,
        label: label
      })
  }

  const onChangeGeneratePeriod = (selection) => {
    track('DataFeeds Generated - ' + selection)
    if (selection === 'daily') {
      setEveryDay(defaultDaily.day)
      setTime(defaultDaily.hour)
    } else if (selection === 'weekly') {
      setEveryDay(defaultWeekly.day)
      setTime(defaultWeekly.hour)
    }
    setGeneratePeriod(selection)
  }

  const onChangeEveryDay = (days) => {
    let dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    days.map(day => { if (day.active)
    { track('DataFeeds Generated - Daily ' + dayName[day.value - 1]) }
    })
    setEveryDay(days)
    setIsDaySelected(days.filter(d => d.active).length)
  }

  const onChangeDeliverWay = (selection) => {
    track('DataFeeds Delivered - ' + selection, 'Deliver Report by')
    setDeliverWay(selection)
    setMandatory(false)
  }

  const onChangeSplitFile = (selection) => {
    track(selection ? 'DataFeeds - Splitted' : 'DataFeeds - Not Splitted', 'Split File')
    setSplitFile(selection ? { ...splitFile, isSplit: true } : { ...splitFile, isSplit: false })
  }

  const CalenderCustomInput = ({ value, onClick }) => (
		<button className="schedule-calender-input" onClick={onClick}>
			<i className="ga icon-btn ga-calendar"/>
			{value}
		</button>
  )

  const onChangeTime = (value) => {
    let timeTemp = time
    timeTemp[value.index].hour = value.hour
    setTime(timeTemp)
    forceUpdate()
  }

  const onChangeAmPm = (value) => {
    let timeTemp = time
    timeTemp[value.index].amPm = value.amPm
    setTime(timeTemp)
    forceUpdate()
  }

  const onAddTime = () => {
    if (time.length >= 3)
    { return }

    let timeTemp = time
    timeTemp.push({ hour: 0, amPm: 'AM', index: time.length })
    setTime(timeTemp)
    track('DataFeeds - Additional Time Added')
    forceUpdate()
  }

  const getTimeArray = (index) => {
    let hourArray = { items: [] }
    for (let i = 1; i < 13; i++) {
      hourArray.items.push({ label: `${i}:00`, value: { hour: i, amPm: null, index: index } })
    }
    return hourArray
  }

  const getSelectedOption = (selection, items) => {
    // default is first option
    let selectedOptionObj = items[0]
    if (selection) {
      selectedOptionObj = items.find((period) => {
        return period.value == selection
      })
    }
    return selectedOptionObj
  }

  const emailValidation = (email) => {
    let isValidEmail = isEmail(email, { allow_utf8_local_part: false })
    if (props.userdetails.adminenabled) {
      return isValidEmail
    }
    else {
      let emailDomain = email.split('@')[1]
      let isDomainAllowed = emailDomain && (allowedDomains.indexOf(emailDomain) > -1 || (emailDomain === TN_DOMAIN))
      return isValidEmail && isDomainAllowed
    }
  }

  const onUpdateInputMultipleBox = (val) => {
    setInputInteracted(true)
    setMandatory(!val.isValid || !val.tags.length)
    setEmail([...val.tags])
  }

  useEffect(() => {
    props.clickInactiveSaveContinue > 0 && setMandatory(true)
  }, [props.clickInactiveSaveContinue])

  const getAllClientUsersDomain = () => {
    http
      .get(
        `${Config.adminServiceURL}/v1/client/${props.userdetails.clientid}/userdomains`
      )
      .then((res) => {
        if (!res?.data?.isError) {
          setAllowedDomains(res.data.data)
        }
      })
  }

  useEffect(() => {
    getAllClientUsersDomain()
    setMandatory(false)
  }, [])

  useEffect(() => {
    let state = {
      generatePeriod,
      everyDay,
      time,
      selectedDate,
      postingOnly,
      deliverWay,
      eMail,
      folder,
      s3bucket,
      sendZip,
      splitFile
    }
    let generatePeriodIsValid = (generatePeriod === 'daily' || generatePeriod === 'weekly') ? (everyDay.find(e => e.active === true) !== undefined) : true
    if (!generatePeriodIsValid && generatePeriod === 'daily') {
      state = { ...state, everyDay: defaultDaily.day }
    } else if (!generatePeriodIsValid && generatePeriod === 'weekly') {
      state = { ...state, everyDay: defaultWeekly.day }
    }

    let isSFTPConfigured = props.userdetails.dfpermissions.isSFTPConfigured
    let deliverWayIsValid = deliverWay.toLowerCase() === 'sftps' ? folder.trim() !== '' && isSFTPConfigured : deliverWay === 'email' ? ((inputInteracted || eMail.length) && !mandatory) : s3bucket !== ''
    let isSFTPEnabled = deliverWay.toLowerCase() === 'sftps' && (folder.trim() !== '' && props?.userdetails?.dfpermissions?.isSFTPConfigured)

    props.setStepComplete(deliverWayIsValid, props.hashKey, state, isSFTPEnabled)
  }, [generatePeriod, isDaySelected, time, selectedDate, deliverWay, eMail, folder, s3bucket, splitFile])

  let periods = {
    items: [
      { label: 'Daily', value: 'daily' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Monthly', value: 'monthly' },
      { label: 'Quarterly', value: 'quarterly' }
    ]
  }

  // filtering the report frequency as per client setting
  let filteredRF = periods.items.filter(it => reportfrequency.some(({ name }) => it.label.toLocaleLowerCase() === name.toLocaleLowerCase()))
  periods.items = filteredRF

  let deliverOptions = {
    items: [
      { label: 'Email', value: 'email' },
      { label: 'Upload by SFTP/FTPS', value: 'SFTPS' },
      { label: 'Upload to S3 Bucket', value: 'BucketAzure' }
    ]
  }

  let deliverOption = getSelectedOption(deliverWay, deliverOptions.items)
  let selectedPeriod = getSelectedOption(generatePeriod, periods.items)
  let isCalendarRangeSelected = props.currentFeedData.config.jobPosting.range?.isCalendarRangeSelected
  return (<div className="step-sub-container data-feed-schedule">
				<div className="main-container no-padding">
					<div className="col-md-5 col-md-offset-1 left-section no-padding color-blue">
					<div className={`${isCalendarRangeSelected ? 'date-range-selected--disabled' : ''}`}>
						<div className="input-caption">Generate report</div>
						<div className={'drop-down-generate-period schedule-36-30'}>
							<DropDown
								multiselect={false}
								selectionChange={(val) => onChangeGeneratePeriod(val)}
								placeHolderValue={selectedPeriod.label}
								defaultPlaceHolderValue={selectedPeriod.label}
								option={periods}
							/>
						</div>
						{(generatePeriod === 'daily' || generatePeriod === 'weekly') && <div className={'every-day-period schedule-36-30'}>
							<div className={'input-caption input-caption-left-label'}>Every:</div>
							<SerialBoxs boxs={everyDay} onChangeBox={onChangeEveryDay}/>
						</div>}
						{(generatePeriod === 'daily' || generatePeriod === 'weekly' || generatePeriod === 'monthly') && <div className={'starting-date-period schedule-36-30'}>
							<div className="input-caption input-caption-left-label">Starting on:</div>
							{generatePeriod === 'monthly'
							  ? <DatePicker
									selected={selectedDate}
									onChange={date => setSelectedDate(date)}
									dateFormat={'MMMM'}
									showMonthYearPicker
									customInput={<CalenderCustomInput />}/>
							  : <DatePicker
									selected={selectedDate}
									onChange={date => setSelectedDate(date)}
									dateFormat={'MMM d, yyyy'}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									customInput={<CalenderCustomInput />}/>}
						</div>}
						{(generatePeriod === 'daily' || generatePeriod === 'weekly') && <div className={'starting-hour-period schedule-36-30'}>
							<div className="input-caption input-caption-left-label">At this time:</div>
							{time.map((time, i) => (
								<div className={i === 0 ? 'first-row' : 'normal-row'}>
									<DropDown
										className={'time-drop-down'}
										multiselect={false}
										selectionChange={onChangeTime}
										placeHolderValue={`${time.hour}:00`}
										defaultPlaceHolderValue={time}
										option={getTimeArray(i)}/>
										<div className="multi-boxs-button">
											<a onClick={() => onChangeAmPm({ amPm: 'AM', index: i })} className={`btn btn-secondary-outline ${time.amPm === 'AM' ? 'active' : 'inactive'}`} role="button">AM</a>
											<a onClick={() => onChangeAmPm({ amPm: 'PM', index: i })} className={`btn btn-secondary-outline ${time.amPm === 'PM' ? 'active' : 'inactive'}`} role="button">PM</a>
                      <span>{formatMessage({ id: 'utc' })}</span>
                    </div>
								</div>))}
							{generatePeriod === 'daily' && <A className={'link'} onClick={() => { onAddTime() }}>+ Add additional run time</A>}
						</div>}
					</div>
					</div>
					<div className="middle-section color-red col-md-1"/>
					<div className="right-section color-green col-md-4 no-padding">
						<div className="input-caption">Deliver report by</div>
						<DropDown
							multiselect={false}
							selectionChange={onChangeDeliverWay}
							placeHolderValue={deliverOption.label}
							defaultPlaceHolderValue={deliverOption.label}
							option={deliverOptions}
						/>
						{deliverWay === 'email' &&
						<div>
              <div className='input-box'>
                <div>
                  <span className="email-to">Send email to </span>
                  <span className="ga ga-info"><PopoverTooltip position="bottom">{'Separate email addresses with a comma when adding multiple recipients.'} </PopoverTooltip> </span>
                  <span className="email-limit">Limit of 20 email addresses</span>
                </div>
                <MultipleEmailInputbox isInputEmpty= {inputInteracted ? !eMail.length : false} selectedEmails= {deliverEmail} onUpdate={onUpdateInputMultipleBox} emailLimit={20} additionalValidation={emailValidation} />
              </div>
							{inputInteracted && (!eMail.length
							  ? <div className={'mandatory-message'}>You must enter email address to continue</div>
							  : mandatory && <div className={'mandatory-message'}>You must enter a valid email address</div>)}
						</div>}
						{deliverWay === 'SFTPS' &&
						<div>
							<Input className={`folder-input ${(mandatory && folder.trim() === '') ? 'mandatory' : ''}`}
								   placeholder={'Folder'}
								   onChange={(e) => { setFolder(e.target.value) }}
								   value={folder}
								   onBlur={() => setMandatory(folder.trim() === '')}/>
							{mandatory && folder.trim() === '' && <div className={'mandatory-message'}>You must enter folder to continue</div>}
							{!props?.userdetails?.dfpermissions?.isSFTPConfigured && <div className={'margin-top-10 mandatory-message'}>SFTP not Configured. Contact <a href="mailto:support@talentneuron.com">support@talentneuron.com</a></div>}
						</div>
						}
						{deliverWay === 'BucketAzure' &&
						<div>
							<Input className={`s3bucket-input ${(mandatory && s3bucket.trim() === '') ? 'mandatory' : ''}`}
								   placeholder={'s3bucket'}
								   onChange={(e) => { setS3bucket(e.target.value) }}
								   value={s3bucket}
								   onBlur={() => setMandatory(s3bucket.trim() === '')}/>
							{mandatory && s3bucket.trim() === '' && <div className={'mandatory-message'}>You must enter S3 Bucket to continue</div>}
						</div>
						}
						<div className="input-caption">Split file:</div>
						<DropDown
							multiselect={false}
							selectionChange={onChangeSplitFile}
							placeHolderValue={splitFile.isSplit ? 'Split' : "Don't split"}
							defaultPlaceHolderValue={false}
							option={{
							  items: [
							  { label: "Don't split", value: false },
							  { label: 'Split', value: true }
							  ]
							}}
						/>

						{splitFile.isSplit
						  ? <IsSPlitFileInput splitFile={splitFile} setSplitFile={setSplitFile}/>
						  : null
						}
					</div>
				</div>
			</div>
  )
}

function useForceUpdate () {
  const [value, setValue] = useState(0)
  return () => setValue(value => value + 1)
}

const IsSPlitFileInput = ({ setSplitFile, splitFile }) => {
  const [input, setInput] = useState(splitFile.value ? splitFile.value : '')
  const formatNumber = () => {
    if (!input) {
      return
    }
    setSplitFile({ isSplit: true, value: parseInt(input) })
    setInput(numberWithCommas(input))
  }
  const focus = () => {
    if (isNaN(input)) {
      setInput(parseInt(input.replace(/,/g, '')))
    }
  }
  const update = (value) => {
    if (!isNaN(value)) {
      setInput(value)
    }
  }

  return (
		<div className="isSplitInput__container">
			<span className="isSplitInput__container--lable">After</span>
			<input pattern="[0-9]*" onFocus={focus} onBlur={formatNumber} value={input} placeholder="000000" onChange={(e) => update(e.target.value)}/>
			<span className="isSplitInput__container--lable">lines/rows</span>

		</div>
  )
}

export default injectIntl(Schedule)
