import React, { useContext, useState, forwardRef, useImperativeHandle } from 'react'
import { Redirect } from 'react-router'
import { Button } from 'talentneuron---ui-components-library'
import { Context } from '../../context'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ConfirmationFeedModal from '../ModalBoxes/confirmationFeedModal'

const Step = forwardRef((props, ref) => {
  let [redirect, setRedirect] = useState(false)
  const [showConfirmationFeedModal, setShowConfirmationFeedModal] = useState(false)
  let ctx = useContext(Context)

  useImperativeHandle(ref, () => ({
    goNext () {
      props.nextStep()
    }
  }))
  const onSaveContinue = async () => {
    ctx.setBuildValidationErrorMsg('')
    if (props.currentStep === props.totalSteps) {
      setShowConfirmationFeedModal(true)
    } else {
      const result = await props.saveDataFeed(props.currentStep)
      if (result) {
        props.nextStep()
      }
    }
  }

  const generateFeed = async () => {
    props.setCompleteDataFeedClicked(true)
    closeConfirmationModal()
  }

  const onCloseSave = async () => {
    const result = await props.saveDataFeed(props.currentStep)
    if (result) {
      setRedirect(true)
    }
  }

  const onClose = () => {
    setRedirect(true)
  }

  const onBack = () => {
    !ctx.isEditMode && props.onBack()
    props.previousStep()
  }

  const closeConfirmationModal = () => { setShowConfirmationFeedModal(false) }

  if (redirect) {
    ctx.setBuildValidationErrorMsg('')
    return <Redirect push to="/" />
  }
  let isDeliverSemiComplete = props.isSFTPStepComplete
  // hiding the save and close btn for oneTimefeed
  let hideSaveAndClose = (ctx.currentFeedData.isOneTimeRun && props.currentStep === 4) || (ctx.currentFeedData.statusId === 4 && props.currentStep === 1)
  return (
    <div className={`step-main-container ${props.hashKey}`} style={{ height: `${window.innerHeight - 300}px` }}>
      { showConfirmationFeedModal && <ConfirmationFeedModal intl={props.intl} message={ctx.currentFeedData.isOneTimeRun ? 'onetime_confirmation_message' : 'schedule_confirmation_message'} generateFeed={generateFeed} closeConfirmationModal={closeConfirmationModal}></ConfirmationFeedModal>}
      {props.isActive &&
        <div className={`row step-${props.isActive ? 'active' : 'inactive'}`}>
          {props.children}
        </div>}

      <div className="controls-bar-container">
        <div className={'main-container'}>

          {props.currentStep > 1 && <Button onClick={onBack} type={'secondary'}
            outline={true} className="step-control back-btn">Back</Button>}

          <Button
            onClick={props.isStepComplete && !props.savingInProgress
              ? onSaveContinue
              : () => {
                  props.showValidationErrorHandler()
                  props.onClickInactiveSaveContinue(props.hashKey)
                }}
            type={(props.isStepComplete) ? 'primary' : 'disabled'}
            className="step-control save-continue-btn">
            {props.currentStep < 4 ? 'Save and continue' : ctx.currentFeedData.isOneTimeRun ? 'Run feed' : 'Complete Data feed'}
          </Button>

          {!hideSaveAndClose && <Button
            onClick={(props.isStepComplete || !!isDeliverSemiComplete) && !props.savingInProgress
              ? onCloseSave
              : () => {
                  props.showValidationErrorHandler()
                }}
            type={(props.isStepComplete || !!isDeliverSemiComplete) ? 'secondary' : 'disabled'}
            outline={true}
            className="step-control save-close-btn">
            Save and close</Button>}

          <Button onClick={onClose} type={'secondary'} outline={true} className="step-control close-btn">Close</Button>
                </div>
            </div>
        </div>
  )
})
Step.contextTypes = { intl: PropTypes.object.isRequired }

export default injectIntl(Step)
