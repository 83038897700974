import React from 'react'
import './styles.css'

function ProgressIndicator (props) {
  return (

    <div className={'step' + (props.isLastStep ? ' last-right-step' : '') + (props.isActive ? ' current' : '')}>
        <div className="step-content">
            {/* {props.leftConnector && <div className="step-left-connector" />} */}
            <div className={'step-number' + (props.isActive ? ' current' : '')}>{props.stepNumber}
                <div className="step-text">{props.stepName}</div>
            </div>
        </div>
        {props.rightConnector && <div className="step-right-connector" />}
    </div>
  )
}

export default ProgressIndicator
