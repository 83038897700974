import React from 'react'
import './EthnicDiversityPopup.css'
import { Button } from 'tn-components-library'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

function EthnicDiversityPopup ({ intl, onClose, popupRef }) {
  return (
    <div className="ethnic-popup-overlay">
      <div className="ethnic-popup-content" ref={popupRef}>
        <h2>{intl.formatMessage({ id: 'ethnic_diversity_coverage_title' })}</h2>
        <table className="ethnic-coverage-table">
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'ethnic_supply_white' })}</th>
              <th>{intl.formatMessage({ id: 'ethnic_supply_other' })}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_white' })}<br /><span className="ethnic-previous-name">{intl.formatMessage({ id: 'ethnic_previous_white' })}</span></td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_us_uk_sa' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_black' })}<br /><span className="ethnic-previous-name">{intl.formatMessage({ id: 'ethnic_previous_black' })}</span></td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_us_uk_sa' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_other' })}</td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_us_uk_sa' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_asian' })}</td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_us_uk_sa' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_latino' })}<br /><span className="ethnic-previous-name">{intl.formatMessage({ id: 'ethnic_previous_latino' })}</span></td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_us' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_mixed' })}</td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_uk' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_indian_asian' })}</td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_sa' })}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'ethnic_supply_coloured' })}</td>
              <td>{intl.formatMessage({ id: 'ethnic_coverage_sa' })}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <span className="ethnic-know-more">
            {intl.formatMessage({ id: 'ethnic_diversity_methodology_note' })}<br />
            <a
              href="https://talentneuron.my.site.com/Support/s/article/AboutTheData-Ethnic-diversity"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'ethnic_diversity_methodology_link_text' })}
            </a>
            {intl.formatMessage({ id: 'ethnic_coverage_support' })}
          </span>
        </p>
        <Button
          className="ethnic-close-btn"
          category="primary"
          label={intl.formatMessage({ id: 'close_button_label' })}
          onClick={onClose}
        />
      </div>
    </div>
  )
}

EthnicDiversityPopup.contextTypes = {
  intl: PropTypes.object.isRequired
}

export default injectIntl(EthnicDiversityPopup)
