import React, { useState } from 'react'
import TooltipButton from './TooltipButton'
import { Popover } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import FeedsApi from '../../httpService/feeds'

const DeleteConfirmation = (props) => {
  return (
        <Popover id={'data-feed-delete-confirmation-' + props.data.id} placement="bottom"
                     className={'data-feed-delete-confirmation ' + props.className}>
        <div className="container-fluid no-padding">
            <p>Are you sure you want to delete this search?</p>
            <div className="button-row">
                <a className={'btn btn-primary-outline deleteButton'} onClick={(e) => {
                  props.onClickDelete(props.data.id, e)
                }}>Delete</a>
                <a className={'btn btn-secondary-outline cancelButton'} onClick={(e) => {
                  props.onClickCancel(null, e)
                }}>Cancel</a>
            </div>
        </div>
    </Popover>)
}
const RowButtons = (props) => {
  const history = useHistory()
  const [deleteId, setDeleteId] = useState(null)
  const [showDelete, setShowDelete] = useState(false)

  const onClickEdit = (id) => {
    FeedsApi.getFeedData(id).then(response => {
      if (!response.data.errorData) {
        let rawData = response.data.data[0]
        if (rawData.isOneTimeRun) { delete rawData.config.deliver }
        props.globalState.dispatch({ type: 'set_feed_data', payload: { ...rawData } })
        props.globalState.setIsEditMode(true)
        props.globalState.setBuildValidationErrorMsg('')
        history.replace('/build-search')
        return response.data.data
      }
    }).catch(err => {
      console.log('error while getting config types', err)
    })
  }
  const onResume = () => {
    // this.setPlayNowsstatus(false)
    FeedsApi.resume({
      updatedBy: props.globalState.userdetails.userid + ',' + props.globalState.userdetails.firstname,
      userId: props.globalState.userdetails.userid,
      feedId: props.data.id
    }).then(response => {
      if (!response.data.IsError)
      {
        props.updateDataFeedStatus()
        return response.data }
      else console.error(response.data.Message)
    }).then(data => {
      // if we want display confirmation(no mockup yet)
    }).catch(err => {
      console.error(err)
    })
  }

  const onPause = () => {
    FeedsApi.pause({
      updatedBy: props.globalState.userdetails.userid + ',' + props.globalState.userdetails.firstname,
      userId: props.globalState.userdetails.userid,
      feedId: props.data.id
    }).then(response => {
      if (!response.data.IsError)
      { props.updateDataFeedStatus()
        return response.data }
      else console.error(response.data.Message)
    }).then(data => {
      // if we want display confirmation(no mockup yet)
    }).catch(err => {
      console.error(err)
    })
  }

  const onRunNow = () => {
    props.setShowRunNowProgress(false)
    setTimeout(() => {
      props.setShowRunNowProgress(true)
    })
    FeedsApi.runNow({ jobName: props.data.id, jobGroup: props.globalState.userdetails.userid }).then(response => {
      if (!response.data.IsError)
      { return response.data }
      else console.error(response.data.Message)
    }).then(data => {
      // if we want display confirmation(no mockup yet)
    }).catch(err => {
      console.error(err)
    })
  }

  const showConfirmation = (id, e) => {
    setDeleteId(id)
    setShowDelete(!showDelete)
  }

  const deleteDataFeed = (id, e) => {
    props.deleteDataFeed(id, e)
  }

  const onClickDelete = (e) => {
    props.deleteDataFeed(props.data.id, e)
    setDeleteId(props.data.id)
    setShowDelete(!showDelete)
  }

  const onClickCopy = (data) => {
    props.copyDataFeed(data)
  }

  const onClickSend = (data, e) => {
    props.shareDataFeed(data)
  }
  const shouldRunNow = props.data.statusName !== 'pending'
  return (
            <div className={'row-button ' + (showDelete && props.data.id === deleteId ? 'active' : '')}>
                { props.data.statusId == 3 && (<TooltipButton
                    id={'resume'}
                    onClick={onResume}
                    className={'ga ga-square-play'}
                    toolTip={'Resume'}
                />) }

                { props.data.statusId == 1 &&
                <TooltipButton
                    id={'pause'}
                    onClick={onPause}
                    className={'ga ga-square-pause'}
                    toolTip={'Pause'}
                />
                }
                 {shouldRunNow && <TooltipButton
                    id={'runNow'}
                    onClick={onRunNow}
                    className={'ga ga-run'}
                    toolTip={'Run now'}
                />
                }
                {/* { props.data.statusId != 4 && */}
                <TooltipButton
                    id={'editDataFeed'}
                    onClick={() => onClickEdit(props.data.id)}
                    className={'ga ga-pencil'}
                    toolTip={'Edit'}/>
                {/* } */}
                <TooltipButton
                    id={'copyDataFeed'}
                    onClick={() => onClickCopy(props.data)}
                    className={'ga ga-copy'}
                    toolTip={'Copy'}/>
                {<TooltipButton
                    id={'shareDataFeed'}
                    onClick={() => onClickSend(props.data)}
                    className={'ga ga-share'}
                    toolTip={'Share'}/>}
                <TooltipButton
                    id={'deleteDataFeed'}
                    onClick={onClickDelete}
                    className={'ga ga-trash'}
                    toolTip={'Delete'}/>
                <DeleteConfirmation
                    className={(showDelete && props.data.id === deleteId) && 'changeItToOpen'}
                    data={props.data}
                    onClickDelete={deleteDataFeed}
                    onClickCancel={showConfirmation}
                />

            </div>
  )
}

export default RowButtons
