
if (!window.df) {
  window.df = {}
  window.df.Config = {}
  window.df.UserContext = {}
}

class Config {
  static get loginURL () {
    return window.df.Config.loginurl || 'http://datafeeds.tndev.aws.talentneuron.com'
  }

  static get logoutURL () {
    return window.df.Config.s3logouturl || 'http://home.tndev.aws.talentneuron.com'
  }

  static get adminServiceURL () {
    return window.df.Config.tnadminserviceurl || 'http://admin-publicservice.tndev.aws.talentneuron.com/'
  }

  static get appURL () {
    return window.df.Config.dataFeedURL || 'http://datafeeds.tndev.aws.talentneuron.com'
  };

  static get getUserContext () {
    return (window.df.UserContext ? window.df.UserContext : null)
  };

  static get helpCenterURL () {
    return window.df.Config.helpcenterurl || 'https://talentneuron.my.site.com/Support/s'
  };

  static get s3URL () {
    return window.df.Config.s3url || 'https://dev-s3.cebglobal.com/s3/'
  };

  static get tnr2xURL () {
    return window.df.Config.tnr2xurl || 'http://recruit.tndev.aws.talentneuron.com'
  };

  static get tnp2xURL () {
    return window.df.Config.plan2xurl || 'http://plan.tndev.aws.talentneuron.com'
  };

  static get customRolesURL () {
    return window.df.Config.customeroleurl || 'http://customroles.tndev.aws.talentneuron.com'
  };

  static get tnHomePageUrl () {
    return window.df.Config.tnhomepageurl || 'http://home.tndev.aws.talentneuron.com'
  };

  static get gaTrackingCode () {
    return window.df.Config.gaTrackingCode || 'UA-87798143-2'
  };

  static get dataFeedUrl () {
    return window.df.Config.dataFeedURL || 'http://datafeeds.tndev.aws.talentneuron.com'
  }

  static get tnpAdmin () {
    return window.df.Config.tnadminurl || 'http://admin.tndev.aws.talentneuron.com'
  };

  static get tnAxonApiURL () {
    return window.df.Config.axonDocURL ? window.df.Config.axonDocURL : 'http://home.tndev.aws.talentneuron.com/api'
  }

  static get kcAuthUrl () {
    return window.df.Config.kcAuthUrl || 'http://axon-keykloak-service.tndev.aws.gartner.com'
  }

  static get staffingURL () {
    return window.df.Config.staffingURL || 'http://staffing.tndev.aws.talentneuron.com'
  }

  static get kcAuthRealm () {
    return window.df.Config.kcAuthRealm || 'tn-ui'
  }

  static get kcAuthClient () {
    return window.df.Config.kcAuthClient || 'tnui-web-app'
  }
}

// Path
export default Config
