import React, { useEffect, useState } from 'react'
import CircularProgressBar from '../CircularProgressBar'
import './style.scss'
function RunNow ({
  runningLabel, completedLabel,
  interval,
  duration,
  timeOutDuration,
  setShowRunNowProgress
}) {
  const [percentage, setPercentage] = useState(0)
  const [label, setLabel] = useState(runningLabel)
  const [showCancel, setShowCancel] = useState(false)
  useEffect(() => {
    if (duration > timeOutDuration) {
      throw ('duration should less than timeOutDuration')
    }
    setLabel(runningLabel)
    let count = 0
    const intervalId = setInterval(() => {
      count = count + 1
      const persent = (interval / duration * 100) * count
      setPercentage(persent)
      if (duration - (count * interval) == 0) {
        setLabel(completedLabel)
        setShowCancel(true)
        clearInterval(intervalId)
        count = 0
      }
    }, interval)
    const timeOutId = setTimeout(() => {
      setShowCancel(false)
      setShowRunNowProgress(false)
    }, timeOutDuration)
    return () => {
      clearTimeout(timeOutId)
    }
  }, [])

  const onCancelClick = () => {
    setShowCancel(false)
    setShowRunNowProgress(false)
  }

  return (

    <div className={showCancel ? 'run-now-backdrop in-active' : 'run-now-backdrop'}>
      <div className="run-now-message">
        <span className="running-query">{label}</span>
        <span className={showCancel && 'cancel'}>
          <CircularProgressBar strokeWidth="5" sqSize="34" percentage={percentage} />
        </span>
        <button onClick={onCancelClick} className={showCancel ? 'cancel-button' : 'cancel-button hide'}>
          <i title="Close" className="ga ga-close01 icon-animation" type="button"></i>
        </button>
      </div>
    </div>
  )
}

RunNow.defaultProps = {
  runningLabel: null,
  completedLabel: null,
  interval: 20,
  duration: 2000,
  timeOutDuration: 5000,
  setShowRunNowProgress: (value) => { }
}

export default RunNow
