import React from 'react'
import { ProductIds, TalentNeuronLogo, UniversalSearchV2, UtilityBar } from 'tn-components-library'
import { Consumer } from '../../context'
import Config from '../../profiles'
import http from '../../httpService/http'
import httpService from '../../httpService/feeds'
import KeyCloakAuthInstance from '../../keyAuth'
import Announcement from '../Announcement'
import { getRoles } from '../../utils'

const endClientMimic = (url) => {
  http.delete(url)
    .then(response => {
      if (response.data.isError) {
        console.log('End mimic client session API Failed - IsError is true')
        return
      }
      KeyCloakAuthInstance.logout()
    })
    .catch(error => {
      console.log('End mimic client session API Failed')
      console.log(error)
    })
}

const handleEndSession = (utilityBarDetails) => {
  const mimicUrl = Config.adminServiceURL + '/v1/mimicclient'
  endClientMimic(mimicUrl)
}

export default function Header (props) {
  const recentSearchTracking = async (searchDetails) => {
    await httpService.recentSearchTracking(searchDetails)
  }

  const UniversalSearchConfig = (userdetails) => {
    return {
      roles: getRoles(userdetails?.geminiroles),
      planAccess: userdetails.tnp2xenabled,
      recruitAccess: userdetails.tnr2xenabled,
      customRoleAccess: userdetails.customroleenabled,
      dataFeedAccess: true,
      activeProduct: ProductIds.DATAFEEDS,
      urls: {
        customrole: `${Config.customRolesURL}/`,
        insight: `${Config.tnHomePageUrl}/learnmore/insights`,
        plan: `${Config.tnp2xURL}/#`,
        recruit: `${Config.tnr2xURL}/#`,
        datafeeds: Config.dataFeedUrl,
        home: Config.tnHomePageUrl,
        customSolution: `${Config.tnHomePageUrl}/customsolutions`
      },
      insights: props.insights,
      recentSearches: props.recentSearchData
    }
  }
  return (
    // this is the way to get data from global state
    <Consumer>
      {
        value => {
          const utilityBarDetails = {
            isProspectMimic: false,
            clientName: value.userdetails.clientname
          }
          return (
            <div className={'top-header-container snapshot-hide'}>
              {value.userdetails.mimicclient && <UtilityBar utilityBarDetails={utilityBarDetails} handleEndSession={handleEndSession} />}
              <header className={`header-wrapper ${value.userdetails.mimicclient ? 'header-with-utility' : ''}`} id='navbar'>
                <div className='header-content'>
                  <a href={Config.tnHomePageUrl} target='_self'>
                    <TalentNeuronLogo width='150' height='22' />
                  </a>
                </div>
                <UniversalSearchV2
                  {...UniversalSearchConfig(value.userdetails)}
                  onClickCallback={recentSearchTracking}
                />
                <Announcement />
              </header>
            </div>
          )
        }
      }
    </Consumer>

  )
}
