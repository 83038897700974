import React, { useState, useEffect } from 'react'
import { A, Button, Input, Checkbox } from 'talentneuron---ui-components-library'

function CustomFields (props) {
  const [isEdit, setEdit] = useState(false)
  const [newField, setNewField] = useState('')

  useEffect(() => {
    if (props.customField && typeof props.customField === 'string') {
      setEdit(true)
      setNewField(props.customField)
    }
  }, [props.customField])

  const onSaveCustomField = () => {
    if (newField) {
      props.onSaveCustomField(newField)
    }
    setNewField('')
    setEdit(false)
  }

  return (
        <div className={'custom-fields'}>
            {isEdit && <div className={'custom-fields-input'}>
                <Checkbox checked={false} name={''} disable={true}/>
                <Input onChange={(e) => { setNewField(e.target.value) }} value={newField} placeholder={newField || ''}/>
                <Button onClick={() => { onSaveCustomField() }} type={`${newField ? 'secondary' : 'disabled'}`} outline={!!newField} className="save-close-btn new-field">Save</Button>
            </div>}
            <A className={'add-link'} onClick={() => setEdit(!isEdit)}>+ Add custom field</A>
        </div>
  )
}

export default CustomFields
