import Config from '../profiles'
import { trackSessionStatus } from '../httpService/tracking'
import KeyCloakAuthInstance from '../keyAuth'

const gtmDataLayer = function () {
  return window.dataLayer || []
}

let flags = {}

const defaultData = {
  page: '',
  category: '',
  action: '',
  label: '',
  dimension5: null,
  dimension6: null,
  dimension7: null
}

const init = (userContext) => {
  if (!userContext) {
    return null
  }
  const sessionId = KeyCloakAuthInstance.getParsedToken().sid;
	if (!sessionId || sessionId === undefined) {
		return null
	}
  trackSessionStatus()
  gtmDataLayer().push({
    product: 5,
    user: {
      userId: userContext.userid,
      sessionId
    },
    trackingUrl: `${Config.adminServiceURL}/v1/ut/userInteraction`
  })
}

const events = {
  // MUST prefix "tnr." to all event names: need to be defined in Backend first
  DataFeedView: {
    event: 'tnr.DataFeedClick',
    action: 'Logged in'
  },
  CreatNewDataFeed: {
    event: 'tnr.CreatNewDataFeed',
    action: 'New data feed'
  },
  DataFeedScheduler: {
    event: 'tnr.CreatNewDataFeed'
  },
  click: {
    event: 'tnr.click'
  },
  search: {
    event: 'tnr.search'
  }

}

const track = (gtmEvent, eventData) => {
  const trackingInfo = { ...defaultData, ...gtmEvent, ...eventData }
  if (trackingInfo.category && trackingInfo.action) {
    gtmDataLayer().push(trackingInfo)
  }
}

const trackWidgetEvent = (gtmEvent, eventData, isExpanded) => {
  eventData.category += ` widget${isExpanded ? ' expanded' : ''}`
  track(gtmEvent, eventData)
}

const resetFlags = () => flags = {}

export default { init, events, track, trackWidgetEvent, resetFlags }
