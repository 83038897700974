/* eslint-disable quotes */
/* eslint-disable no-trailing-spaces */
import React from 'react'
import Config from '../../../profiles'
import { isEmpty } from '../../../utils'
import http from '../../../httpService/http'

const scheduleDays = { 1: 'sun', 2: 'mon', 3: 'tue', 4: 'wed', 5: 'thu', 6: 'fri', 7: 'sat' }

export const isDeliverBySFTP = (config) => {
  return !!config?.deliver?.deliveryBy?.sftp
}

const getKeyword = (data) => {
  if (data.translatedKeyword && data.userQuery) {
    return {
      keyword: data
    }
  }
  const translatedKeyword = data.json.map(el => ({ ...el, generated: el.generated.toString() }))
  return {
    keyword: {
      translatedKeyword,
      userQuery: data.original
    }
  }
}

const getSearchData = (other, selectedLocations, selectedEmployer, keywords, showAnonymous, showStaffing) => {
  let search = {}
  let formattedLocation = {}
  let keywordsData = {}
  const { location, query, employer, ...otherThanLocation } = other

  if (!isEmpty(selectedEmployer)) {
    otherThanLocation.employer = selectedEmployer
  }
  if (!isEmpty(otherThanLocation)) {
    search = getSearchFilters(otherThanLocation)
  }
  if (!isEmpty(selectedLocations)) {
    const groupedLocation = groupBy(selectedLocations, 'type')
    formattedLocation = getSearchFilters(groupedLocation)
  }
  if (search && isEmpty(formattedLocation)) return
  if (keywords && !isEmpty(keywords)) {
    keywordsData = getKeyword(keywords)
  }
  return {
    search: {
      ...formattedLocation,
      ...search,
      ...keywordsData,
      showAnonymous,
      showStaffing
    }
  }
}

export const getBuildSearch = (data = null) => {
  let search = {}
  const { items, selectedLocations, selectedEmployer, keywords, jobPosting, showAnonymous, showStaffing, ...other } = data
  if (!isEmpty(items) || !isEmpty(selectedLocations)) {
    search = getSearchData(items, selectedLocations, selectedEmployer, keywords, showAnonymous, showStaffing)
  }
  return {
    ...search,
    jobPosting
  }
}

export const getReport = (data = null) => {
  let tempStamp = {}
  if (data.timeStamp !== 'Select Format') {
    tempStamp = {
      timestampFormat: data.timeStamp
    }
  }
  return {
    report: {
      filename: data.fileName.toLowerCase(),
      filetype: data.fileType.toLowerCase(),
      reportType: data.fileConfig.key.toLowerCase(),
      ...tempStamp
    }
  }
}

export const getConfig = (data = null) => {
  let result1 = []
  let result2 = []
  const allFieldsWithOrder = data.selectedList.map((el, index) => ({
    id: el.fieldId,
    name: el.fieldName,
    categoryName: el.categoryName,
    order: index,
    savedLimit: !!el.charLimit,
    charLimit: el.charLimit
  })
  )
  const fields = allFieldsWithOrder.filter(el => el.categoryName.toLowerCase() !== 'custom fields')
  const customFields = allFieldsWithOrder.filter(el => el.categoryName.toLowerCase() === 'custom fields').map(el => ({
    name: el.name,
    categoryName: el.categoryName,
    order: el.order,
    charLimit: el.charLimit,
    savedLimit: el.savedLimit,
    id: 10000 + Number(el.order)
  }))
  if (fields.length > 0) {
    result1 = {
      fields
    }
  }
  if (customFields.length > 0) {
    result2 = {
      customFields
    }
  }
  const dataResult = {
    config: {
      ...result1,
      ...result2
    }
  }
  const { categoryName, ...result } = dataResult

  return result
}

const getDeliverData = (data) => {
  if (!data) return
  let result = {}
  switch (data.deliverWay) {
    case 'BucketAzure' :
      result = {
        s3: {
          s3bucket: data.s3bucket.replace('s3://', ''),
          notifyByEmail: data.notifyMe,
          accessKeyId: data.accessKeyId,
          secretAccessKey: data.secretAccessKey
        }
      }
      break

    case 'email' :
      let address = Array.isArray(data.eMail) ? data.eMail : [data.eMail]
      result = {
        email: {
          address
        }
      }
      break

    case 'SFTPS' :
      result = {
        sftp: {
          host: data.host,
          folder: data.folder,
          userName: data.userName,
          password: data.password
        }
      }
      break
  }
  return {
    sendAsZip: data.sendZip,
    splitAfter: data.splitFile.isSplit ? data.splitFile.value : 0,
    deliveryBy: {
      ...result
    }

  }
}
const getScheduleData = (data) => {
  if (!data) return
  let day = data.everyDay.filter(el => el.active).map(d => { return scheduleDays[d.value] })
  let time = data.time.map(el => { return convert12HTo24H(el.hour, el.amPm, 0) })
  let date = new Date(data.selectedDate)
  let startingDate
  startingDate =
        date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + (date.getDate())).slice(-2)
  const extraData = (data.generatePeriod === 'weekly' || data.generatePeriod === 'daily') ? { day, time } : {}
  return {
    ...extraData,
    frequency: data.generatePeriod,
    startingDate,
    startingMonthId: date.getMonth() + 1,
    excludePreviousJob: data.postingOnly
  }
}
export const getSchedule = (data = null) => {
  const deliver = getDeliverData(data)
  const schedule = getScheduleData(data)
  if (isEmpty(deliver) && isEmpty(schedule)) return
  return {
    deliver,
    schedule
  }
}
const removeScheduleDetails = (fullData) => {
  let data = fullData
  delete data.config.deliver
  return data
}
export const getFullCurrentStepData = (fullData, step) => {
  let data, name, rangeSelection
  let isOneTimeFeed = !!step.state.jobPosting?.range?.isCalendarRangeSelected
  switch (step.hashKey) {
    case 'build-search':
      data = getBuildSearch(step.state)
      rangeSelection = { isOneTimeRun: isOneTimeFeed }
      name = step.state.searchName
      break
    case 'select-file-type':
      data = getReport(step.state)
      break
    case 'configure-file':
      data = getConfig(step.state)
      break

    case 'schedule':
      data = getSchedule(step.state)
      break
  }
  let isActiveFeedEditing = (fullData.statusId === 1 && isOneTimeFeed)
  fullData = isActiveFeedEditing ? removeScheduleDetails(fullData) : fullData
  return {
    ...fullData,
    ...rangeSelection,
    config: {
      ...fullData.config,
      ...data
    },
    name: name || fullData.name
  }
}
const formatSearchItems = (items) => {
  if (items.seniority) {
    items.experienceLevel = items.seniority
    delete items.seniority
  }
  if (items.educationlevel) {
    items.educationLevel = items.educationlevel
    delete items.educationlevel
  }
  if (items.semicleantitle) {
    items.title = items.semicleantitle
    delete items.semicleantitle
  }
  if (items.employmenttype) {
    items.employmentType = items.employmenttype
    delete items.employmenttype
  }

  return items
}

const getSearchFilters = (items) => {
  const formattedItems = formatSearchItems(items)
  if (isEmpty(formattedItems)) return
  let result = {}
  for (const property in formattedItems) {
    const d = formattedItems[property].filter(el => el.checked).map(el => {
      return `${el.selectedLogic}:${el.id}`
    })
    result = { ...result, [property]: d }
  }

  return result
}

export const groupBy = (items, field) => {
  return items.reduce((r, a) => {
    r[a[field]] = [...r[a[field]] || [], a]
    return r
  }, {})
}

const convert12HTo24H = (hour, amPM) => {
  return amPM === 'AM' && hour === 12 ? 0 : amPM === 'PM' && hour !== 12 ? hour + 12 : hour
}

export const isFeedInComplete = (curData) => {
  const mainAttributes = ['jobPosting', 'search', 'report', 'config', 'schedule', 'deliver']
  const dfConfig = curData?.config
  let isFeedIncomplete = false
  mainAttributes.forEach(fieldName => {
    if (!dfConfig.hasOwnProperty(fieldName)) {
      isFeedIncomplete = true
    }
  })
  return isFeedIncomplete
}

export const statusIdMapping = {
  active: 1,
  inComplete: 2,
  pause: 3,
  complete_1TimeRun: 4
}

const setStatusId = (currentStepState, currentStepNo, isCurrentStepCompleted, totalSteps, isSFTPConfigured, completeDataFeedClicked) => {
  let Status = statusIdMapping.inComplete

  if (isCurrentStepCompleted) {  
    if (currentStepState.statusId === statusIdMapping.pause && (isFeedInComplete(currentStepState) || currentStepState.isOneTimeRun) && totalSteps !== currentStepNo) {
      Status = statusIdMapping.inComplete
    } 
    else if (currentStepState.statusId === statusIdMapping.pause && completeDataFeedClicked && totalSteps === currentStepNo) {
      Status = statusIdMapping.active
    } 
    else if (currentStepState.statusId === statusIdMapping.pause && completeDataFeedClicked === false) {
      Status = statusIdMapping.pause
    }
    else if ((isFeedInComplete(currentStepState) || currentStepState.isOneTimeRun) && totalSteps !== currentStepNo) {
      Status = statusIdMapping.inComplete
    }  
    else if (currentStepState.isOneTimeRun && !isFeedInComplete(currentStepState)) {
      Status = statusIdMapping.complete_1TimeRun
    }  
    else {
      Status = statusIdMapping.active
    }
  
    if (currentStepNo === totalSteps && isDeliverBySFTP(currentStepState?.config)) {
      Status = isSFTPConfigured ? Status : statusIdMapping.inComplete
    }
  }    

  const tmpData = Object.assign({}, currentStepState)
  tmpData.statusId = Status
  return tmpData
}

export const doSave = async (data, currentstep, isCurrentStepCompleted, totalSteps, isSFTPConfigured, completeDataFeedClicked) => {
  // sometime currentstep is not present in data, that's why receiving is explicitly in param
  console.log("Complete Data Feed Clicked:", completeDataFeedClicked)
  const fullData = setStatusId(data, currentstep, isCurrentStepCompleted, totalSteps, isSFTPConfigured, completeDataFeedClicked)
  const { currentStep, ...other } = fullData
  const url = Config.appURL + '/manager/feed/save'
  const result = await http.post(url, other)

  return result.data
}

export const buildMultiLookupParams = (configSearchAttr) => {
  let params = ''
  for (const [key, value] of Object.entries(configSearchAttr)) {
    const keyFormat = key === 'experienceLevel' ? 'seniority' : key.toLowerCase()
    if (keyFormat === 'occupation') {
      const key = keyFormat
      value.map(el => {
        const id = el.split(':')[1]
        let p = `&${key}=code:${id}`
        params = params + p
      })
    }
    if (['msa', 'city', 'country', 'county', 'state'].indexOf(keyFormat) > -1) {
      value.map(el => {
        const id = el.split(':')[1]
        let p = `&${keyFormat}=country_code:${id}`
        params = params + p
      })
    }
    value.length && value.map(el => {
      const id = el.split(':')[1]
      let p = `&${keyFormat}=name:${id}`
      params = params + p
    })
  }
  return params
}

export const getLocationsOfCountry = (country, resultset) => {
  let matchedItemsWithCategory = {
    countries: 'country', msa: 'msa', states: 'state', counties: 'county', regions: 'regions', cities: 'city'
  }
  let locations = { msas: [], countries: [], states: [], counties: [], regions: [], cities: [] }
  for (const [key, list] of Object.entries(resultset)) {
    list.forEach(item => {
      item.category = 'location'
      if (key === 'countries') {
        if (item.id == country.id) { item.selectedLogic = 'req'; item.type = matchedItemsWithCategory[key]; locations[key].push(item) }
      } else if (key === 'msa') {
        if (item.country.id == country.id) { item.selectedLogic = 'req'; item.type = matchedItemsWithCategory[key]; locations.msas.push(item) }
      } else {
        if (item.country.id == country.id) { item.selectedLogic = 'req'; item.type = matchedItemsWithCategory[key]; locations[key].push(item) }
      }
    })
  }
  return locations
}

export const mappingCategoryWithLookUpServiceKeys = (key) => {
  let mapping = {
    employmenttype: 'employmentType',
    seniority: 'experienceLevel',
    semicleantitle: 'title',
    educationlevel: 'educationLevel'
  }
  return mapping[key] || key
}

export const getURLParamsForLocationsAutocomplete = (country, permissionSet) => {
  let uriParams = ''
  let matchedItemsWithCategory = {
    countries: { category: 'country', values: [] },
    msa: { category: 'msa', values: [] },
    states: { category: 'state', values: [] },
    counties: { category: 'county', values: [] },
    regions: { category: 'region', values: [] },
    cities: { category: 'city', values: [] }
  }
  if (permissionSet.countries.filter(item => item.id == country.id).length) {
    matchedItemsWithCategory.countries.values.push(`${country.code}`)
    uriParams = `country=${matchedItemsWithCategory.countries.values[0]}&`
  } else {
    for (const [key, list] of Object.entries(permissionSet)) {
      list.forEach(item => {
        if (key !== 'countries') {
          if (item.country.id == country.id) {
            matchedItemsWithCategory[key].values.push(`${item.id}-id`)
          }
        }
      })
      uriParams = (matchedItemsWithCategory[key].values.length)
        ? (uriParams + `${matchedItemsWithCategory[key].category}=${matchedItemsWithCategory[key].values.join()}&`)
        : uriParams
    }
  }
  return uriParams
}

export const generateTranslatedFromResponseData = function (resp) {
  var result = ''
  for (var ii = 0; ii < resp.length; ii++) {
    result = result + (resp[ii].value == 'PHRASE' ? ' " ' : ' ' + resp[ii].value + ' ')
  }
  return result.trim().replace(/\s\s+/g, ' ')
}

export const countQueryWords = (text) => {
  if (!text) return 0
  var count = text.filter(function (elt) {
    if (elt.type == 'expression') {
      return true
    }
  }).length
  return count
}

export const highlightKeywords = (text) => {
  var word = text.split(' ')
  var newHTML = ''
  var activeOther = ''

  word.forEach((value, index, arr) => {
    switch (value) {
      case 'AND':
      case 'NOT':
      case 'OR':
        if (activeOther != '') {
          newHTML += "<span class='other'>" + activeOther + '</span>'
          activeOther = ''
        }
        newHTML += "<span class='statement'>" + ' ' + value + ' ' + '</span>'
        break
      case '(':
      case ')':
      case "'":
      case '"':
        if (activeOther != '') {
          newHTML += "<span class='other'>" + activeOther + '</span>'
          activeOther = ''
        }
        newHTML += "<span class='statement'>" + value.trim() + '</span>'
        break
      default:
        if (activeOther == '') {
          activeOther += value.trim()
        } else {
          activeOther += ' ' + value.trim()
        }
    }
  })
  return newHTML + activeOther
}
