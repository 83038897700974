import React, { useState, useEffect, useContext, useRef } from 'react'
import { Input, DropDown } from 'talentneuron---ui-components-library'
import { isEmpty, timeStampFormat } from '../../utils'
import FeedsApi from '../../httpService/feeds'
import { Context } from '../../context'
import '../../scss/FormValidation.scss'
import { injectIntl } from 'react-intl'

function SelectFileType (props) {
  const { formatMessage } = props.intl
  let fileTypeOptions = {
    items:
			[
			  { label: '.XLSX', value: '.xlsx' },
			  { label: '.CSV', value: '.csv' },
			  { label: '.TXT', value: '.txt' }
			]
  }
  props.userdetails.dfpermissions.xmlHeader.namespace.length && fileTypeOptions.items.push({ label: '.XML', value: '.xml' })
  let TimeStampOptions = {
    items:
			[
			  { label: formatMessage({ id: 'select_format' }), value: formatMessage({ id: 'select_format' }) },
			  { label: formatMessage({ id: 'YYMMDDHHNNSS' }), value: formatMessage({ id: 'YYMMDDHHNNSS' }) },
			  { label: formatMessage({ id: 'HHNNSSYYYYMMDD' }), value: formatMessage({ id: 'HHNNSSYYYYMMDD' }) },
			  { label: formatMessage({ id: 'MMDDHHNN' }), value: formatMessage({ id: 'MMDDHHNN' }) }
			]
  }
  const [fileConfig, setFileConfig] = useState({ label: formatMessage({ id: 'select' }) })
  const [fileName, setFileName] = useState('')
  const [isFileNameValid, setIsFileNameValid] = useState(false)
  const [isFileNameDirty, setIsFileNameDirty] = useState(false)
  const [timeStamp, setTimeStamp] = useState(formatMessage({ id: 'select_format' }))
  const [fileType, setFileType] = useState('.xlsx')
  const [fileConfigTypes, setFileConfigTypes] = useState({ items: [] })

  useEffect(() => {
    if (!isEmpty(props.currentFeedData)) {
      const report = props.currentFeedData?.config?.report
      if (report) {
        setTimeStamp(report.timestampFormat)
        setFileName(report.filename)
        setFileType(report.filetype)
        setFileConfig(report.reportType === 'job' ? { label: formatMessage({ id: 'rows_are_job_postings' }), key: 'job', id: 1 } : { label: formatMessage({ id: 'rows_are_employers' }), key: 'employer', id: 2 })
        setIsFileNameDirty(true)
      }
    }
  }, [props.currentFeedData])
  useEffect(() => {
    !fileConfigTypes.items.length && getFileConfigTypes()
    props.setStepComplete(fileConfig.key && isFileNameValid && fileName && fileName.length > 0, props.hashKey, { fileConfig, fileName, timeStamp, fileType, fileConfigTypes })
    validateFileName(fileName)
  }, [fileConfig, fileName, timeStamp, fileType, fileConfigTypes, isFileNameValid])

  const validateFileName = (val) => {
	  if (val) {
		  const regexp = /^[a-zA-Z0-9-_]+$/
		  setIsFileNameValid(regexp.test(val))
	  } else {
	  setIsFileNameValid(true)
    }
  }

  const onFileConfigChange = (selection) => {
    setFileConfig(fileConfigTypes.items[selection - 1])
  }

  const onTimeStampChange = (timeFormat) => {
    setTimeStamp(timeFormat)
  }

  const onFileTypeChange = (val) => {
    setFileType(val)
  }

  const getFileConfigTypes = () => {
    FeedsApi.getFileConfigTypes().then(response => {
      if (!response.data.errorData) {
        response.data.data.forEach(item => { item.label = `${formatMessage({ id: 'rows_are' })} ${item.name}`; item.value = item.id })
        return response.data.data
      }
    }).then(data => {
      const dataWithKey = data.map(el => ({ ...el, key: el.id === 1 ? 'job' : 'employer' }))
      setFileConfigTypes({ items: dataWithKey })
    }).catch(err => {
      console.log('error while getting config types', err)
    })
  }

  return (
		<div className="step-sub-container">
			<div className="main-container">
				<div className="col-md-3">
					<div className="input-caption">{formatMessage({ id: 'file_configuration' })}</div>
					<DropDown
						className={'filter input col-xs-12 no-padding'}
						multiselect={false}
						selectionChange={
							onFileConfigChange
						}
						placeHolderValue={fileConfig.label}
						option={fileConfigTypes}
					/>
				</div>
				<div className="col-xs-4">
					<div className="input-caption">{formatMessage({ id: 'file_name' })}</div>
					<Input
						className={`filter input col-xs-12 ${((!isFileNameValid && isFileNameDirty) && 'invalid')} ${(props.enableCheck && fileName.length === 0) ? 'mandatory' : ''}`}
						onChange={(e) => { setFileName(e.target.value); setIsFileNameDirty(true) } }
						value={fileName}
						placeholder={formatMessage({ id: 'file_name' })}
					/>
					<p className={`description  ${((!isFileNameValid && isFileNameDirty) && 'invalid')}`}>{formatMessage({ id: 'file_name_validation_message' })}</p>
				</div>
			<div className="col-md-3">
				<div className="input-caption">{formatMessage({ id: 'timestamp_optional' })}</div>
				<DropDown
					className={'filter input col-xs-12 no-padding'}
					multiselect={false}
					selectionChange={
						onTimeStampChange
					}
					placeHolderValue={timeStamp || formatMessage({ id: 'select_format' })}
					option={TimeStampOptions}
				/>
			</div>
				<div className="col-xs-2">
					<div className="input-caption">{formatMessage({ id: 'file_type' })}</div>
					<DropDown
						className={'filter input col-xs-12 no-padding'}
						multiselect={false}
						selectionChange={
							onFileTypeChange
						}
						placeHolderValue={fileType.toUpperCase() || '.XLSX'}
						defaultPlaceHolderValue="xlsx"
						option={fileTypeOptions}
						selectedValue={fileType}
					/>
				</div>
			</div>
		</div>
  )
}

export default injectIntl(SelectFileType)
