import React from 'react'
import { Modal, Button } from 'talentneuron---ui-components-library'

export default function ConfirmationFeedModal ({ intl, message, generateFeed, closeConfirmationModal }) {
  return (
    <Modal isModalOpen={true} override={false} backDrop={true} containerClassName="edit-oneTimeFeed-modal" isCloseOnOustsideClick={false} >
        <div className='modal-line-separator'>{intl.formatMessage({ id: message })}</div>
        <div className='modal-line-separator'> {intl.formatMessage({ id: 'wish_to_proceed' })}</div>
        <div className="button-row">
            <Button type={'primary'} className="pull-right" value={intl.formatMessage({ id: 'yes' })} onClick={() => generateFeed()} />
            <Button type={'secondary'} outline={true} className="pull-right close-btn" value={intl.formatMessage({ id: 'no' })} onClick={() => closeConfirmationModal()} />
        </div>
    </Modal>
  )
}
