import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material'
import http from '../../httpService/http'
import { ProductIds, AccountPageIds, UniversalNavV2 } from 'tn-components-library'
import { Context } from '../../context'
import Config from '../../profiles'
import { AppUrls, getRoles } from '../../utils'
import KeyCloakAuthInstance from '../../keyAuth'

const NavigationWrapper = styled('div')(() => {
  return {
    display: 'flex',
    'input:active, input:focus': {
      border: 'none'
    }
  }
})

const getInsights = () => {
  let url = Config.adminServiceURL + '/v1/cms/gettninsights'
  return new Promise((resolve) => {
    http
      .post(url, {
        categories: 'All',
        pageno: 1,
        totalrecords: 100,
        fromdate: '2020-01-01',
        todate: '2099-12-31',
        searchtext: ''
      })
      .then((response) => {
        resolve(response.data?.data?.insightslist || [])
      })
      .catch((e) => {
        resolve([])
      })
  })
}

const getUniversalNavConfig = (userObj) => {
  const onPasswordChangeHandler = () => {
    let url = `${window.location.origin}/changePassword?token=${KeyCloakAuthInstance.getAccessToken()}&redirecturl=${KeyCloakAuthInstance.getLogoutUrl()}`
    window.open(url, '_self')
  }

  const roles = getRoles(userObj.geminiroles)
  const staffingRole = roles.find(x => x.pageCode === 'TalentNeuron Staffing')
  return {
    urls: {
      learnmore: AppUrls.HomeURL + '/learnmore',
      insight: AppUrls.HomeURL + '/learnmore/insights',
      plan: AppUrls.PLAN2x + '#',
      recruit: AppUrls.Tnr2xURL + '#',
      customrole: AppUrls.CustomRoleURL,
      admin: AppUrls.AdminURL,
      datafeeds: AppUrls.DataFeedURL,
      myaccount: AppUrls.HomeURL + '/home/myaccount',
      password: () => onPasswordChangeHandler(),
      home: AppUrls.HomeURL,
      customSolution: AppUrls.HomeURL + '/customsolutions',
      axon: AppUrls.HomeURL + '/home/requestapiaccess',
      staffing: AppUrls.StaffingURL
    },
    planAccess: userObj.tnp2xenabled,
    recruitAccess: userObj.tnr2xenabled,
    customRoleAccess: userObj.customroleenabled,
    dataFeedAccess: true,
    adminAcess: userObj.adminenabled,
    roles,
    activeProduct: ProductIds.DATAFEEDS,
    isAlertsEnabled: userObj.isAlertsEnabled,
    isStaffing: staffingRole ? staffingRole.isEnabled : false
  }
}

const SideNav = ({ setViewAsModalOpen, logout, insights = [] }) => {
  const contextValue = useContext(Context)
  const history = useHistory()
  const menuClickCallback = (menuItem) => {
    switch (menuItem.id || menuItem.pageId) {
      case AccountPageIds.VIEW_AS: {
        setViewAsModalOpen(true)
        break
      }
      case AccountPageIds.LOGOUT: {
        logout()
        break
      }
      default: {
        history.push(menuItem.path || '/')
      }
    }
  }

  return (
    <NavigationWrapper>
      <UniversalNavV2
        {...getUniversalNavConfig(contextValue.userdetails)}
        menuClickCallback={(menu) => menuClickCallback(menu)}
        insights={insights}
      />
    </NavigationWrapper>
  )
}

export default SideNav
