import React, { useEffect, useState } from 'react'

import { FeatureAnnouncement } from 'tn-components-library'
import http from '../../httpService/http'
import Config from '../../profiles'
import Cookies from 'js-cookie'
const getAllNotificationByDate = () => {
  const date = new Date()
  const currentDate =
    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  return new Promise((resolve, reject) => {
    http
      .get(
        `${Config.adminServiceURL}/v1/cms/getnotificationbydate?date=${currentDate}`
      )
      .then((res) => {
        if (res?.data?.isError) {
          reject([])
          return
        }
        resolve(res?.data)
      })
  })
}

const filterFeatureAnnouncements = (announcements = []) => {
  const productPermission = {
    Recruit: window.df.UserContext.tnr2xenabled,
    Plan: window.df.UserContext.tnp2xenabled,
    Custom_Roles: window.df.UserContext.customroleenabled,
    Admin: window.df.UserContext.adminenabled,
    Datafeeds: window.df.UserContext.datafeedsenabled
  }
  const featureAnnouncements = announcements
    .filter((announcement) => {
      let tag = announcement.platformtag
      if (tag) {
        tag = tag.replace(/\s+/g, ' ')?.trim()?.replace(' ', '_')
      }
      if (tag === null || productPermission[tag]) {
        return true
      }
      return false
    })
    .sort((prev, next) => (prev.id > next.id ? -1 : 1))
  return featureAnnouncements
}

const getRecentFeatureAnnouncement = (setFeatureAnnouncement) => {
  getAllNotificationByDate().then((response) => {
    const featureAnnouncements = filterFeatureAnnouncements(
      response?.data?.notifications || []
    )
    setFeatureAnnouncement(featureAnnouncements[0] || null)
  })
}

const Announcement = () => {
  const [featureAnnouncement, setFeatureAnnouncement] = useState(null)
  useEffect(() => getRecentFeatureAnnouncement(setFeatureAnnouncement), [])
  if (!featureAnnouncement) return null
  const { id, message, title, ctaurl, enddate } = featureAnnouncement
  return (
    <FeatureAnnouncement
      id={id}
      message={message}
      title={title}
      ctaUrl={ctaurl}
      endDate={enddate}
    />
  )
}

export default Announcement
