import React, { useEffect, useState } from 'react'
import { ErrorModal, ShareModal, ShareSuccessModal, PillBox, LoadingLines, useDebounce } from 'tn-components-library'
import './style.scss'
import { Context } from '../../../context'
import { useQuery } from 'react-query'
import { getUsersOfAccountManagement, getUsersOfSharedSearch, getSearchParamsForSharedSearch, getSharedSearchLookupData, shareDataFeed, sendEmailForSharedDF } from '../../../aspects/api/sharedsearch'
import { isEmpty } from '../../../utils'
import { buildMultiLookupParams } from '../../Step/helpers/buildSearchHelper'
import Config from '../../../profiles'
import { SHARE_MODAL_TABS, DEFAULT_STATE, OPERATOR } from './config'
import { getKey } from './utils'

const ShareDFModal = ({ showShareModal, shareModalData, closeShareDataFeed, myCompanyUsers, tnUsers, setUsers, intl }) => {
  // ? Props info: myCompanyUsers & tnUsers will hold the data at parent component so we need not make additional calls for consecutive shares, setUsers is setter function for it.

  //* constants
  const contextState = React.useContext(Context)
  const sharedProjectName = shareModalData.name
  const sharedProjectId = shareModalData.id
  const restrictToUserClient = !contextState.userdetails.adminenabled ? `&clientid=${contextState.userdetails.clientid}` : ''

  const shareSearchUserFetchConfigQuery = {
    refetchOnWindowFocus: false,
    refetchOnMount: true
  }

  //* States
  const [shareData, setShareData] = useState(DEFAULT_STATE)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [searchParamsArray, setSearchParamsArray] = useState([])
  const [inputValue, setInputValue] = useState('')
  const debouncedValue = useDebounce(inputValue, 400)
  const [tmpMyCompanyUsers, setTmpMyCompanyUsers] = useState([]) // will hold the changing value for myCompanyUsers
  const [tabValue, setTabValue] = useState(SHARE_MODAL_TABS.mycompany)

  //* React-query functions
  const getSharedSearchCompanyUsers = () => {
    return getUsersOfSharedSearch(debouncedValue, restrictToUserClient)
  }

  const getSharedSearchTNUsers = () => {
    return getUsersOfAccountManagement()
  }

  const getSharedSharedSearchParams = () => {
    return getSearchParamsForSharedSearch(sharedProjectId)
  }

  const getLookupData = (searchParamsData) => {
    return getSharedSearchLookupData(searchParamsData)
  }

  //* Functions
  const handleShare = async () => {
    const receivers = shareData.selectedEmails.map((el) => ({
      geminiUserId: el.id,
      clientName: el.clientName
    }))
    // API call to share the datafeed
    const { data } = await shareDataFeed(sharedProjectId, sharedProjectName, receivers)

    if (!data.IsError || !data.isError) {
      setShowSuccessModal(true)
      // On success of the share datafeed call, we make additional call to sendEmail
      const subject = intl.formatMessage({ id: 'shareModal_email_subject' })
      const productName = intl.formatMessage({ id: 'shareModal_email_productName' })
      const appName = intl.formatMessage({ id: 'shareModal_email_appName' })
      const receiverFormat = shareData.selectedEmails.map((el) => ({
        name: el.firstname + ' ' + el.lastname,
        email: el.email
      }))

      const emailNotificationPayload = {
        isCopyMe: shareData.isCopyMeSelected,
        subject: subject,
        message: shareData.emailMessage,
        productName: productName,
        appUrl: Config.appURL,
        itemNameToShare: shareModalData.name,
        appName: appName,
        receivers: receiverFormat
      }
      sendEmailForSharedDF(emailNotificationPayload)
    } else {
      // If share call fails then we show the error modal
      setShowErrorModal(true)
    }
  }

  //* React-query api calls
  // For my company users we make api call and fetch 200 records and make additional api calls as the user types
  const {
    data: companyUsers,
    isLoading: isCompanyUsersLoading,
    isFetching: isMyCompanyUsersFetching,
    refetch: fetchMyCompanyUsers
  } = useQuery('my-company-users', getSharedSearchCompanyUsers, {
    ...shareSearchUserFetchConfigQuery,
    refetchInterval: false,
    enabled: false,
    select: ({ data }) => {
      const dfUsersArray = data.data.filter(eachObject => eachObject.dfAccess)
      return dfUsersArray.map((object) => {
        return {
          ...object,
          id: object.userid,
          label: `${object.firstname} ${object.lastname} <${object.email}>`
        }
      })
    }
  })

  const {
    data: tnUsersArray = [],
    isLoading: isTnUsersLoading,
    isFetching: isTnUsersFetching,
    refetch: fetchTnUsers
  } = useQuery('tn-users', getSharedSearchTNUsers, {
    ...shareSearchUserFetchConfigQuery,
    enabled: false,
    select: ({ data }) => {
      const dfUsersArray = data.data.filter(eachObject => eachObject.dfAccess)
      return dfUsersArray.map((object) => {
        return { ...object, id: object.userid, label: object.firstname + ' ' + object.lastname }
      })
    }
  })

  // Call to fetch the ids of the selected filters in the datafeed
  const {
    data: searchParamsData = [[], ''],
    refetch: fetchSharedSearchParams,
    isLoading: isSearchParamsLoading,
    isFetching: fetchingSearchParams
  } = useQuery('shared-search-params', getSharedSharedSearchParams, {
    ...shareSearchUserFetchConfigQuery,
    enabled: false,
    select: ({ data }) => {
      const search = data.data[0].config?.search
      if (!data.isError && !isEmpty(data.data[0].config) && search && !isEmpty(search)) {
        const { customList, keyword, ...other } = search
        return [other, buildMultiLookupParams(other)]
      }
    }
  })

  // After getting the ids, lookup call to fetch the labels for them
  const {
    data: tmpSearchParamsArray = [],
    isLoading: isSharedParamsLoading,
    refetch: fetchLookupData,
    isFetching: fetchingLookups
  } = useQuery('search-params-lookup', () => getLookupData(searchParamsData[1]), {
    enabled: false,
    ...shareSearchUserFetchConfigQuery,
    select: ({ data }) => {
      if (!data.isError) {
        const [searchData] = searchParamsData
        // Computation to extract the operator: req, not, opt for the filter params
        const formattedData = data.data.map((el, idx) => {
          const valuesArray = searchData[getKey(el.key)]
          let label = el.value
          const foundValue = valuesArray.find((eachValue) => {
            const [, id] = eachValue.split(':')
            return parseInt(id) === parseInt(el.id)
          })
          const [operator] = foundValue.split(':')

          if (el.key === 'function' && parseInt(el.id) === 0) {
            label = intl.formatMessage({ id: 'miscellaneous_function' })
          }

          return { id: parseInt(el.id), label: label, operator: operator, field: el.field, key: el.key }
        })
        // filtering out duplicate countryCodes & occupation codes
        return formattedData.filter((eachData) => eachData.field !== 'country_code' && eachData.field !== 'code')
      }
    }
  })

  const renderSharedSearchPillsHeader = () => {
    if (isSharedParamsLoading || isSearchParamsLoading || fetchingSearchParams || fetchingLookups) {
      return <LoadingLines numberOfLoaders={10} />
    }
    if (searchParamsArray.length) {
      return searchParamsArray.map((eachData) => {
        return <PillBox key={eachData.id} type={eachData.operator === OPERATOR.not ? 'exclude' : 'default'} label={eachData.label} id={eachData.id} maxLength={35} readOnly={true} />
      })
    }
  }

  const onShare = (formData) => {
    setShareData(formData)
  }

  const handleSuccessErrorModalCLose = () => {
    closeShareDataFeed()
  }

  const handleShareModalClose = () => {
    closeShareDataFeed()
    setSearchParamsArray([])
    setShareData(DEFAULT_STATE)
  }

  //* UseEffects

  useEffect(() => {
    if (tabValue === SHARE_MODAL_TABS.mycompany) {
      fetchMyCompanyUsers()
      // Fetching my company users as user types
    }
  }, [debouncedValue])

  useEffect(() => {
    setTmpMyCompanyUsers(myCompanyUsers)
  }, [myCompanyUsers])

  useEffect(() => {
    if (inputValue === '') {
      // When input is empty without making api call again setting the value from parent state
      setTmpMyCompanyUsers(myCompanyUsers)
    }
  }, [inputValue])

  useEffect(() => {
    if (shareData.selectedEmails.length) {
      handleShare()
    }
  }, [shareData])

  useEffect(() => {
    if (companyUsers?.length) {
      setUsers(companyUsers, 'myCompanyUsers')
      // Storing this data in the parent component state to avoid consecutive api calls
    }
  }, [companyUsers])

  useEffect(() => {
    if (tnUsersArray.length) {
      // Storing this data in the parent component state to avoid consecutive api calls
      setUsers(tnUsersArray, 'tnUsers')
    }
  }, [tnUsersArray])

  useEffect(() => {
    if (!tnUsers.length) {
      // Checking If data already present in the parent component, otherwise refetch
      fetchTnUsers()
    }
    if (!myCompanyUsers.length) {
      // Checking If data already present in the parent component, otherwise refetch
      fetchMyCompanyUsers()
    }
    fetchSharedSearchParams()
  }, [shareModalData.id])

  useEffect(() => {
    if (searchParamsData[1]?.length) {
      fetchLookupData()
    }
  }, [searchParamsData[1]])

  useEffect(() => {
    if (tmpSearchParamsArray.length) {
      setSearchParamsArray(tmpSearchParamsArray)
    }
  }, [tmpSearchParamsArray])

  const shareModal = (
    <ShareModal
      isLoading={isCompanyUsersLoading || isTnUsersLoading || isTnUsersFetching || isMyCompanyUsersFetching}
      setOpen={closeShareDataFeed}
      sharedProjectName={sharedProjectName}
      className='df-share-modal'
      open={showShareModal}
      myCompanyUsersArray={tmpMyCompanyUsers}
      tnUsersArray={tnUsers}
      renderPills={renderSharedSearchPillsHeader}
      onShare={onShare}
      onClose={handleShareModalClose}
      inputValue={inputValue}
      setInputValue={setInputValue}
      debouncedInputValue={debouncedValue}
      getTabValue={setTabValue}
      suggestionsData={companyUsers}
    />
  )

  const getModal = () => {
    if (showErrorModal) {
      return <ErrorModal open={showErrorModal} setOpen={setShowErrorModal} onClose={handleSuccessErrorModalCLose} errorMessage={intl.formatMessage({ id: 'share_datafeed_error_message' })} />
    } else if (showSuccessModal) {
      return <ShareSuccessModal open={showSuccessModal} setOpen={setShowSuccessModal} SharedEmails={shareData.selectedEmails} name={sharedProjectName} onClose={handleSuccessErrorModalCLose} />
    } else {
      return shareModal
    }
  }

  return <>{getModal()}</>
}

export default ShareDFModal
