import http from './http'

import Config from '../profiles'

export const trackSessionStatus = (isSessionActive = true) => {
  const sessionData = {
    logout: !isSessionActive,
    screenheight: window.screen.availHeight,
    screenwidth: window.screen.availWidth
  }

  return http.post(Config.adminServiceURL + '/v1/ut/trackusersession', sessionData)
}
