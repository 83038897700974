import Config from './profiles'
import {
  GROUP_A_CATEGORYID, GROUP_B_CATEGORYID
} from './constants'
import { ProductIds } from 'tn-components-library'
export const timeConverterFrom24HourTo12Hour = (time) => {
  if (time > 23) {
    return ' Not a valid time should respect this condition 0 < time <= 23'
  }
  let tmpTime = time
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
  if (time.length === 1) {
    time = tmpTime + ':00'

    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
  }
  if (time.length > 1) { // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM UTC' : ' PM UTC' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}

export const getMonthByNumber = (num) => {
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][num]
}

const geTimeType = (date, type) => {
  const lowerCaseType = type.toLowerCase()
  const d = new Date()
  switch (lowerCaseType) {
    case 'yyyy' :
      return d.getFullYear()
      break
    case 'yy' :
      return d.getFullYear().toString().substr(-2)
      break
    case 'mm' :
      return d.getMonth() + 1
      break
    case 'dd' :
      return d.getDate()
      break
    case 'hh' :
      return d.getHours()
      break
    case 'nn' :
      return d.getMinutes()
      break
    case 'ss' :
      return d.getSeconds()
      break
  }
}

export const timeStampFormat = (date, desiredFormat) => {
  let result = ''
  let singleTime = ''
  for (let i = 0; i < desiredFormat.length; i = i + 1) {
    singleTime = desiredFormat[i]
    while (desiredFormat[i + 1] === desiredFormat[i]) {
      singleTime = singleTime + desiredFormat[i + 1]
      ++i
    }
    result = result + geTimeType(date, singleTime)
  }
  return result
}

export const addEllipsis = (name, n) => {
  name = name.length > n ? name.substring(0, n) + '...' : name
  return name
}
export const AppUrls = {
  AdminURL: Config.tnpAdmin,
  Tnr2xURL: Config.tnr2xURL,
  HelpCentreURL: Config.helpCenterURL,
  HomeURL: Config.tnHomePageUrl,
  CustomRoleURL: Config.customRolesURL,
  PLAN2x: Config.tnp2xURL,
  DataFeedURL: '/',
  StaffingURL: Config.staffingURL
}

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

export const cleanUrl = (url) => url && url.replace('??', '?').replace('&&', '&')

export const countryHasSalaryData = (selectedCountryCategoryId) => {
  return (selectedCountryCategoryId === GROUP_A_CATEGORYID)
}

export const countryHasSupplyData = (selectedCountryCategoryId) => {
  return ((selectedCountryCategoryId === GROUP_A_CATEGORYID) || (selectedCountryCategoryId === GROUP_B_CATEGORYID))
}

export const countryDetailsExtractor = (countries, rates, salarySupportedCountries,
  supplySupportedCountries) => {
  return countries.map((elem) => {
    let hasSalaryData = salarySupportedCountries.includes(elem.code)
    let code = ''
    if (hasSalaryData) {
      let countryItem = rates.filter(item => item.countryCode.toLowerCase() === elem.code.toLowerCase())
      code = countryItem.length ? countryItem[0].currencyCode : ''
    }
    return { ...elem, hasSalaryData: hasSalaryData, hasSupplyData: supplySupportedCountries.includes(elem.code), currencyCode: code }
  })
}

const getCountriesList = (countries) => {
  let totalCountriesList = [...window.df.UserContext?.subscribedcountries, ...window.df.UserContext?.unsubscribedcountries]
  const mergedArray = countries.map((item) => {
    const matchedObject = totalCountriesList.find((obj) => obj.code === item.code)
    return { ...item, categoryid: matchedObject?.categoryid }
  })
  return mergedArray
}

export const getCountriesForDropDown = (permissions) => {
  var countries = permissions.locations.countries ? getCountriesList(permissions.locations.countries) : []; var countriesList = [...countries]
  for (const [key, list] of Object.entries(permissions.locations)) {
    if (key != 'countries') {
      list.forEach(item => {
        let isPresent = countriesList.filter(elem => elem.id == item.country.id).length > 0
        if (!isPresent) { countriesList.push(item.country) }
      })
    }
  }
  return countriesList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
}

export const removeParamsFromQueryString = (queryString = '', paramToBeExcluded = '') => {
  let queryStringSplit = queryString.replace('&&', '&').split('&')
  const excludedParamArray = paramToBeExcluded.split(',')
  queryStringSplit = queryStringSplit.filter(x => excludedParamArray.filter(y => !x.startsWith(y)).length === excludedParamArray.length)
  return queryStringSplit.join('&')
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const removetimestamp = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const castToUTCDate = (dateString) => {
  let date = new Date(dateString)
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const formattedDate = (selectedDate) => {
  let date = new Date(selectedDate)
  let startingDate
  return startingDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2)
}

export const getRoles = (roleDetails = []) => {
  let roles = []
  roles = roleDetails.map((role) => {
    return (
      {
        id: role.id,
        pageName: role.name,
        pageCode: role.code,
        isEnabled: role.isenable,
        pageProductName: role.pageProductName,
        permissionDetails: role.permissionDetail || []
      }
    )
  })
  return roles
}
