export const getKey = (key) => {
  switch (key) {
    case 'educationlevel':
      return 'educationLevel'
    case 'employmenttype':
      return 'employmentType'
    case 'seniority':
      return 'experienceLevel'
    default:
      return key
  }
}
