import React, { useState, useEffect } from 'react'
import CheckBoxSection from '../CheckBoxSection'
import DraggableBox from '../DraggableBox'
import CustomFields from '../CustomFields'
import { A, Button, LoadingChart } from 'talentneuron---ui-components-library'

import fieldConfig from './fieldsConfig.json'
import { isEmpty } from '../../utils'
import FeedsApi from '../../httpService/feeds'
import { limitedCoverageFieldIds } from '../../constants'

function ConfigureFile (props) {
  const [selection, setSelection] = useState(null)
  const [sectionsList, setSectionsList] = useState([])
  const [selectedList, setSelectedList] = useState([])
  const [tmpSelectedList, setTmpSelectedList] = useState([])
  const [customField, setCustomField] = useState('')
  const [isCharacterLimitMenuOpen, setCharacterLimitMenuOpen] = useState(false)
  const [configFields, setConfigFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const column = [{ no: 1 }, { no: 2 }, { no: 3 }]

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    getConfigFields(props.currentFeedData.config.report.reportType === 'job' ? 1 : 2)
  }, [props.currentFeedData.config])

  useEffect(() => {
    configFields.length && setSectionsList(formatComponentList(configFields))
  }, [configFields])

  const getConfigFields = (typeId) => {
    setSelection(typeId)
    setIsLoading(true)
    let clientname = props.userdetails.clientname
    FeedsApi.getConfigTypeFields(typeId).then((response) => {
      if (!response.data.errorData) {
        let updatedResponse = []
        if (response.data && response.data.data.length) {
          // TNAXN-2474 - Enable experience level field only to AdeptID
          if (clientname === 'AdeptID' || clientname === 'TestClient_React') {
            updatedResponse = response.data.data
          } else {
            updatedResponse = response.data.data.filter(e => e.fieldId != 158)
          }
          // Add limitedCoverage for specific fieldIds
          updatedResponse = updatedResponse.map(field => {
            field.limitedCoverage = false
            if (limitedCoverageFieldIds.includes(field.fieldId)) {
              field.limitedCoverage = true
            }

            return field
          })
        }

        if (!isEmpty(props.currentFeedData)) {
          const feedFileTypeConfig = props.currentFeedData.config
          if (!isEmpty(feedFileTypeConfig) && feedFileTypeConfig.config) {
            const fieldsListSelected = feedFileTypeConfig.config.fields || []
            const tempSelectedList = []

            fieldsListSelected.map(el => {
              if (updatedResponse.find(res => res.fieldId === el.id)) {
                tempSelectedList.push(el)
              }
            })

            const fieldsListCustomSelected = feedFileTypeConfig.config.customFields?.map(item =>
              item.id ? item : { ...item, id: 10000 + Number(item.order) }
            ) || []

            setSelectedList(formatSelectedList(tempSelectedList, false)
              .concat(formatSelectedList(fieldsListCustomSelected, true))
              .sort((a, b) => a.order - b.order)
            )

            setConfigFields(updatedResponse.concat(fieldsListCustomSelected.map(el => ({
              ...el, fieldName: el.name, fieldId: el.id
            }))))
          } else {
            let preSelectedList = []
            let filterFieldIds

            if (typeId === 1) {
              filterFieldIds = [1, 2]
            } else {
              filterFieldIds = [13, 14]
            }

            const updatedFields = updatedResponse.map((el) => {
              if (filterFieldIds.includes(el.fieldId)) {
                preSelectedList.push({ ...el, content: el.fieldName, id: `item-${el.fieldId}` })
                return { ...el, isChecked: true }
              }
              return el
            })

            setConfigFields(updatedFields)
            setSelectedList(preSelectedList)
          }
        }
      } else {
        console.error(JSON.stringify(response))
      }
    }).catch(error => {
      throw (error)
    })
  }

  const selectAll = () => {
    let tempSelectedList = []
    setSectionsList(sectionsList.map(el => {
      el.fields = el.fields.map((elField, i) => {
        tempSelectedList.push({ ...elField, id: `item-${i}${elField.fieldId}`, content: elField.fieldName })
        return { ...elField, isChecked: true }
      })
      return el
    }))
    setSelectedList(tempSelectedList)
  }

  const clearAll = () => {
    setCharacterLimitMenuOpen(false)
    setSelectedList([])
    setSectionsList(formatComponentList(configFields, true))
  }

  const categoryCheckboxHandler = (value) => {
    let tempSelectedList = selectedList; let tempSectionsList

    tempSectionsList = sectionsList.map(el => {
      if (el.categoryName === value) { el.sectionIsChecked = !el.sectionIsChecked }
      el.fields = el.fields.map((elField) => {
        if (!el.sectionIsChecked) {
          tempSelectedList.splice(tempSelectedList.findIndex(el => el.fieldId === value.id), 1)
          return { ...elField, isChecked: false }
        }
        return { ...elField }
      })
      return el
    })

    setSelectedList(tempSelectedList)
    setSectionsList(tempSectionsList)
  }

  const checkboxHandler = (name, value) => {
    let tempSelectedList = selectedList
    let tempSectionsList = sectionsList.map(el => {
      if (el.categoryName === value.category) {
        el.fields = el.fields.map((elField) => {
          if ((value.category === 'Custom fields' && elField.fieldId === value.id) || elField.fieldId === value.id) {
            if (elField.isChecked) {
              tempSelectedList.splice(tempSelectedList.findIndex(el => el.fieldId === value.id), 1)
              el.sectionIsChecked = false
              return { ...elField, isChecked: false }
            } else {
              tempSelectedList.push({ ...elField, id: `item-${elField.fieldId}`, content: elField.fieldName })
              return { ...elField, isChecked: true }
            }
          }
          return { ...elField }
        })
      }
      return el
    })
    setSelectedList(tempSelectedList)
    setSectionsList(tempSectionsList)
  }

  const formatComponentList = (fieldRawData, clearAll) => {
    let formatedData = fieldConfig[props.currentFeedData.config.report.reportType]
    props.currentFeedData.config.report.reportType === 'job' && props.userdetails.dfpermissions.includeJobTags && !formatedData.find((el) => el.categoryName === 'Job tags') && formatedData.push({
      type: 'checkBox',
      isSubCategory: false,
      column: 3,
      categoryName: 'Job tags',
      fields: []
    })

    formatedData.map(categories => {
      let tempCategory = fieldRawData.filter((data) => {
        return data.categoryName === categories.categoryName
      })
      categories.fields = tempCategory.map(el => {
        let find = categories.categoryName === 'Custom fields' ? selectedList.find(sl => sl.fieldName === el.fieldName) : selectedList.find(sl => sl.fieldId === el.fieldId)
        if (find) {
          el.isChecked = !clearAll
          el.fieldName = find.fieldName
        }
        return el
      })
    })
    setIsLoading(false)
    return formatedData
  }

  const formatSelectedList = (fieldRawData) => {
    return fieldRawData.map(el => ({
      ...el,
      id: `item-${el.id}`,
      fieldId: el.id,
      fieldName: el.name,
      content: el.name
    }))
  }

  const onReorder = (items) => {
    setSelectedList(items)
  }

  const saveCustomField = (items) => {
    let catIndex = sectionsList.findIndex(el => el.categoryName === 'Custom fields')
    if (!sectionsList[catIndex].fields.find((el) => el.fieldName === items)) {
      let temp = {
        fieldId: `id-temp-${items}`,
        fieldName: items,
        field_category_id: 'Custom fields',
        categoryName: 'Custom fields'
      }
      sectionsList[catIndex].fields.push(temp)
      configFields.push(temp)
      setSectionsList(sectionsList)
      setConfigFields(configFields)
      forceUpdate()
    }
  }

  const editField = (oldValue, newValue) => {
    let tempSectionsList = sectionsList.map(el => {
      if (el.categoryName === oldValue.categoryName) {
        el.fields = el.fields.map((elField) => {
          let edit = false
          if (elField.fieldId === oldValue.fieldId) {
            if (newValue === undefined) {
              edit = true
            } else if (newValue !== '') {
              elField.fieldName = newValue
              let valueIndex = selectedList.findIndex(sl => sl.fieldId === elField.fieldId)
              if (valueIndex > -1) {
                selectedList[valueIndex].fieldName = newValue
                selectedList[valueIndex].content = newValue
                setSelectedList(selectedList)
              }
            }
            return { ...elField, edit: edit }
          }
          else return { ...elField, edit: false }
        })
      }
      return el
    })
    setSectionsList(tempSectionsList)
  }

  const removeCustomField = (ev, items) => {
    let catIndex = sectionsList.findIndex(el => el.categoryName === 'Custom fields')
    let valueIndex = sectionsList[catIndex].fields.findIndex(el => el.fieldName === items)
    valueIndex >= 0 && sectionsList[catIndex].fields.splice(valueIndex, 1)

    valueIndex = selectedList.findIndex(el => el.fieldId === `id-temp-${items}`)
    valueIndex >= 0 && selectedList.splice(valueIndex, 1)

    valueIndex = configFields.findIndex(el => el.fieldId === `id-temp-${items}`)
    valueIndex >= 0 && configFields.splice(valueIndex, 1)

    setSelectedList(selectedList)
    setSectionsList(sectionsList)
    setConfigFields(configFields)
    forceUpdate()
  }

  const onOpenCharacterLimitMenu = () => {
    setTmpSelectedList(selectedList)
  }

  const onClearLimit = (items) => {
    setSelectedList(tmpSelectedList.map((entries) => {
      entries.charLimit = 0
      entries.savedLimit = false
      return entries
    }))
  }

  const setCharacterLimitValue = (limit, selectedField) => {
    // copy with .map to make it immutable
    const list = tmpSelectedList.map(el => ({ ...el }))
    setTmpSelectedList(list.map((entries) => {
      if (entries.fieldId === selectedField.fieldId) {
        entries.charLimit = limit
        entries.savedLimit = false
      }
      return entries
    }))
  }

  const onSaveLimit = () => {
    setSelectedList(tmpSelectedList.map((entries) => {
      if (entries.charLimit) {
        entries.savedLimit = true
        entries.charLimit = parseInt(entries.charLimit)
      }
      return entries
    }))
  }

  useEffect(() => {
    props.setStepComplete((selectedList.length), props.hashKey, { selectedList, sectionsList, configFields })
  }, [selectedList, sectionsList, configFields])

  return (
    <div className="main-container configure-file-fields">
      {isLoading
        ? <div className="loading-container"> <LoadingChart title='' subTitle='' /></div>
        : <div className="step-sub-container step-sub-container-header">
          <div className="configure-file-config input-caption col-xs-9">
            <span>{selection === 1 ? 'Job' : 'Employer'} file configuration</span>
            <A className={'link'} onClick={() => selectAll()}>Select all</A>
            <A onClick={() => clearAll()}>Clear all</A>
          </div>
          <div className="configure-file-config input-caption col-xs-3">
            <span>Organize fields</span>
          </div>
          {column.map((col, i) => (
            <div key={i} className={'col-xs-3 fields-column'}>
              {sectionsList.filter(el => el.column === col.no).map((section, index) => {
                return (
                  <CheckBoxSection
                    key={index}
                    categoryCheckboxHandler={() => categoryCheckboxHandler}
                    type={section.type}
                    sectionIsChecked={section.sectionIsChecked}
                    className={`${section.categoryName.toLowerCase().replace(/ /g, '-')}`}
                    editField={editField}
                    removeCustomField={removeCustomField}
                    checkboxHandler={() => checkboxHandler}
                    isSubCategory={section.isSubCategory}
                    categoryName={section.categoryName}
                    fieldsList={section.fields}>
                    {section.categoryName === 'Custom fields' && <CustomFields onSaveCustomField={saveCustomField} customField={customField}>{section.categoryName}</CustomFields>}
                  </CheckBoxSection>
                )
              }

              )}
            </div>
          ))}
          <div className={'col-xs-3 configure-file-config panel-container'}>
            <A className={'link'} onClick={() => { onOpenCharacterLimitMenu(); setCharacterLimitMenuOpen(!isCharacterLimitMenuOpen) }}>Set character limits</A>
            <div className={`col-xs-3 organize-fields-panel ${isCharacterLimitMenuOpen ? 'character-limit-menu-open' : 'character-limit-menu-close'}`}>
              <div className={'organize-fields-panel-draggable'}>
                <DraggableBox
                  isCharacterLimitMenuOpen={isCharacterLimitMenuOpen}
                  itemsList={isCharacterLimitMenuOpen ? tmpSelectedList : selectedList}
                  pillClass={'pill-data-feed'}
                  iconClass={'ga pillBoxLogicIcon ga-check02'}
                  onDelete={checkboxHandler}
                  onReorder={onReorder}
                  setCharacterLimitValue={setCharacterLimitValue} />
              </div>
            </div>
            {isCharacterLimitMenuOpen && <div className={'organize-fields-button col-xs-12'}>
              <A className={'cancel-limit pull-left'} onClick={() => { setCharacterLimitMenuOpen(false) }} >Cancel</A>
              <Button onClick={() => { onClearLimit() }} className={'clear-limit'} outline={true} type={'secondary'}>Clear</Button>
              <Button onClick={() => { onSaveLimit(); setCharacterLimitMenuOpen(false) }} className={'save-limit'} type={'primary'}>Save</Button>
            </div>}
          </div>
        </div>}
    </div>
  )
}

function useForceUpdate () {
  const [value, setValue] = useState(0)
  return () => setValue(value => value + 1)
}

export default ConfigureFile
