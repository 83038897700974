import React from 'react'
import { DropDown } from 'talentneuron---ui-components-library'

function CalendarHeader (props) {
  const longMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const handleMonthChange = (monthIndex) => {
    const selectedYear = props.selectedDate.getFullYear()
    props.onChange(new Date(selectedYear, monthIndex))
  }

  const handleYearChange = (year) => {
    const selectedMonth = props.selectedDate.getMonth()
    props.onChange(new Date(year, selectedMonth))
  }

  const getSelectedMonth = (month) => {
    return (props.monthFormat) ? props.monthFormat[month] : longMonth[month]
  }

  const getMonthsList = () => {
    const months = (props.monthFormat) ? props.monthFormat : longMonth; const listObj = { items: [] }
    months.forEach((month, index) => {
      listObj.items.push({ label: month, value: index })
    })
    return listObj
  }

  const getYearsList = () => {
    const years = { items: [] }
    for (let year = props.startDate.getFullYear(); year <= props.endDate.getFullYear(); year += 1) {
      years.items.push({ label: year, value: year })
    }
    return years
  }

  return (
        <div className="calendarHeader">
            <DropDown selectionChange={handleMonthChange} dropDownTypeRenderer={() => getSelectedMonth(props.selectedDate.getMonth())} option={getMonthsList()} />
            <DropDown selectionChange={handleYearChange} dropDownTypeRenderer={() => props.selectedDate.getFullYear()} option={getYearsList()} />
        </div>
  )
};

export default CalendarHeader
