// React imports
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

class ToolTipButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mouseOn: false
    }
    this.handleMouseMove = this.handleMouseMove.bind(this)
  }

  handleMouseMove () {
    let _ref = this.refs['refTooltip' + this.props.id]
    window.onmousemove = function (e) {
      let x = (e.clientX - 30) + 'px'; let y = (e.clientY) + 'px'
      if (_ref && _ref.style) {
        _ref.style.top = y
        _ref.style.left = x
      }
    }
  }

  render () {
    return (
            <div className={`tooltip-button ${this.props.disabled ? 'disabled' : ''}`}>
                 <span className="darktooltip" data-for={`open_project${this.props.id}`} data-tip={this.props.toolTip}>
                    <i
                        ref={this.props.ref}
                        className={`${this.props.className} icon-animation`}
                        id={this.props.id}
                        onClick={this.props.onClick}
                        type="button">
                    </i>
                 </span>
                <ReactTooltip scrollHide={true} place="bottom" id={`open_project${this.props.id}`} className='tooltip' effect="solid" />
            </div>)
  }
}

export default ToolTipButton
