import React, { Component } from 'react'
import { Input } from 'talentneuron---ui-components-library'

class Filter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentVal: ''
    }
    this.setVal = this.setVal.bind(this)
  }

  setVal (currentVal) {
    this.setState({
      currentVal
    }, () => {
      this.props.doFilter(currentVal)
    })
  }

  render () {
    return (
                <Input width={'575px'} className={'filter input'} onChange={(e) => {
                  this.setVal(e.target.value)
                }} value={this.state.currentVal} placeholder={'Search'}/>
    )
  }
}

export default Filter
