import React from 'react'
import { NavLink } from 'react-router-dom'
import gtm from '../../usagetracker/GTM'

export const Banner = (props) => {
  const track = () => {
    gtm.trackWidgetEvent(gtm.events.CreatNewDataFeed,
      {
        category: 'Data Feed',
        label: 'New data feed'
      })
  }

  const startNewDataFeed = () => {
    props.dispatch({ type: 'set_feed_data', payload: {} })
    props.setIsEditMode(false)
    props.setBuildValidationErrorMsg('')
  }

  return (
        <div className={'jumbotron-section'}>
            <div className={'main-container'}>
                <div className={'row'}>
                    <div className={'col-md-9 title-section no-padding'}>
                        <span className={'pull-left title'}>{'Data feeds'}</span>
                    </div>
                    <div className={'col-md-3 no-padding'}>
                        <div className={'pull-right new-data-button'} onClick={() => track()}>
						<NavLink to={{ pathname: '/build-search' }} className="btn btn-primary" onClick={startNewDataFeed}>
							Start new data feed
						</NavLink>
                        </div>
                    </div>
                    <div></div>
                </div>

            </div>

        </div>

  )
}
