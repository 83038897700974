import axios from 'axios'
import cookie from 'react-cookie'
import Config from '../profiles'
import { cleanUrl } from '../utils'
import csrfUtil from '../countryOriginUtil'
import KeyCloakAuthInstance from '../keyAuth'

const http = axios.create({
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
})

http.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${KeyCloakAuthInstance.getAccessToken()}`
  return config
})

http.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && error.response.status === 403) {
      return KeyCloakAuthInstance.logout()
    }
    if (error.response && (error.response.status === 401)) {
      window.location = Config.loginURL
    } else {
      return Promise.reject(error)
    }
  }
)

http.all = axios.all

export default http
