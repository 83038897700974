import cookie from 'react-cookie'
import moment from 'moment'

let Config = {
  interval: 300000,
  s3IndexVal: 1
}

// This will be the Util to generate CSRF token, Names dont match the context just to hide the details
const CountrySpecificOriginStore = (function () {
  let userData = {}
  let token = ''
  let currentS3Token = ''
  let interval = null
  let myObj = {}

  let setCountrySpecificOriginId = () => {
    let appToken = cookie.load('S3Token')
    if (appToken && Object.keys(userData).length) {
      currentS3Token = appToken || userData.s3token
      let s3Tvalue = getS3Tvalue()
      let uEFValue = getUEFvalue()
      let curTStamp = getCurTStamp()
      let newS3TvalueWithTStamp = getModifiedS3Tvalue(s3Tvalue, curTStamp)
      token = `${newS3TvalueWithTStamp}${uEFValue}${curTStamp}`
    }
  }

  // Changing S3Tvalue so that there is a change in entire series
  let getModifiedS3Tvalue = (s3Tvalue, curTStamp) => {
    let newS3TListValue = []
    let curTStampListValue = curTStamp.split('')
    s3Tvalue.split('').forEach((elem, i) => {
      newS3TListValue.push(elem)
      newS3TListValue.push(curTStampListValue[i])
    })
    return newS3TListValue.join('')
  }

  // fetch UTC seconds of timestamp
  let getCurTStamp = () => {
    let currentTStampInSeconds = moment().utc().format('X').toString()
    return currentTStampInSeconds.substring(currentTStampInSeconds.length - 4, currentTStampInSeconds.length)
  }

  // fetch one character's charcode value from each part of s3 token
  let getS3Tvalue = () => {
    let s3val = ''
    currentS3Token.split('-').forEach((elem) => {
      let indexValue = Config.s3IndexVal >= elem.length ? elem.length - 1 : Config.s3IndexVal
      s3val = s3val + elem.charCodeAt(indexValue).toString()[0]
    })
    return s3val
  }

  // UEFL = Userid, Email,firstname
  let getUEFvalue = () => {
    // last digit of char code of first character of userid
    let uidCharCodeValue = userData.userid.toString()[0].charCodeAt().toString()
    let uid = uidCharCodeValue[uidCharCodeValue.length - 1]
    // last digit of char code of first character of emailid
    let eidCharCodeValue = userData.email[0].charCodeAt().toString()
    let eid = eidCharCodeValue[eidCharCodeValue.length - 1]
    let fname = userData.firstname
    // first digit of char code of first character of firstname
    let fid = fname.length ? fname[fname.length - 1].charCodeAt().toString()[0] : 0
    return uid + eid + fid
  }

  let onTabSwitch = () => {
    if (document.hidden) {
      clearInterval(interval)
    } else {
      setCountrySpecificOriginId()
      interval = setInterval(setCountrySpecificOriginId, Config.interval)
    }
  }

  myObj.setUserData = (data) => {
    userData = data != null ? data : {}
    if (data != null) {
      setCountrySpecificOriginId()
      if (!interval) {
        interval = setInterval(setCountrySpecificOriginId, Config.interval)
        document.addEventListener('visibilitychange', onTabSwitch)
      }
      Object.seal(myObj)
    }
  }

  myObj.getCountrySpecificOriginId = () => {
    let appToken = cookie.load('S3Token')
    if (appToken && (currentS3Token != appToken)) {
      setCountrySpecificOriginId()
    }
    return token
  }

  return myObj
}())

export default CountrySpecificOriginStore
