import React from 'react'
import ProgressIndicator from '../ProgressIndicator'

function ProgressManager (props) {
  return (
        <div className="progress-manager-container">
            <div className="main-container">
                <div className="row create-data-feed-title">
                        Create data feed
                </div>
                <div className={'row '}>
                    <div className="col-md-10 col-md-offset-1 progress-indicators-container">
                            {
                                props.steps.map(
                                  (step, id) => {
                                    const isActive = props.currentStep === id + 1
                                    return (
                                            <ProgressIndicator
                                                key={id}
                                                stepName={step.name}
                                                stepNumber={id + 1}
                                                isLastStep={id === props.steps.length - 1}
                                                rightConnector={id !== props.steps.length - 1}
                                                isActive={isActive}
                                            />
                                    )
                                  }
                                )
                            }
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ProgressManager
