import React from 'react'
import { Button, Modal } from 'talentneuron---ui-components-library'
const NameDuplicationModal = ({ handleShowModal, name, onReplaceClick }) => {
  return (
        <Modal containerClassName={'dfNameDuplicationModal'}
               isModalOpen={true} backDrop={true}
               isCloseOnOustsideClick={true}
               closeModal={() => {
               }}>

            <div className="dfNameDuplicationModal__title">
                <span>{name} </span> already exists. Do you want to replace it?
            </div>

            <div className="dfNameDuplicationModal__mainContain">
                Replacing this data feed will overwrite its current contents.
            </div>
            <div className="dfNameDuplicationModal__buttonRow">
                <Button type="primary" value="Back" onClick={handleShowModal} />
                <Button type={'ghost' } value="Replace" onClick={() => onReplaceClick(name)} />
            </div>

        </Modal>
  )
}

export default NameDuplicationModal
